import React from 'react';
import PropTypes from 'prop-types';
import { Table } from 'react-bootstrap';
import MerchantCodeTableRow from './MerchantCodeTableRow';

const MerchantCodeTable = ({data, searchTerm, deleteHandler}) => {
  const searchFilter = (item) => {
    const nameMatch = item.name.toUpperCase().indexOf(searchTerm.toUpperCase()) > -1;
    const pobIdMatch = item.pobId.indexOf(searchTerm) > -1;
    const merchantCodeMatch = item.merchantCode.toUpperCase().indexOf(searchTerm.toUpperCase()) > -1;
    return nameMatch || pobIdMatch || merchantCodeMatch;
  };
  return (
    <Table striped bordered hover>
      <thead>
        <tr>
          <th>pobId</th>
          <th>Name</th>
          <th>Merchant Code</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>
        {data && data.filter(searchFilter).map(item => <MerchantCodeTableRow key={item.pobId} deleteHandler={deleteHandler} item={item}/>)}
      </tbody>
    </Table>
  );
};
MerchantCodeTable.propTypes = {
  data: PropTypes.array,
  searchTerm: PropTypes.string,
  deleteHandler: PropTypes.func
};

export default MerchantCodeTable;