import React from 'react';
import PropTypes from 'prop-types';
import Form from 'react-bootstrap/Form';
import Alert from 'react-bootstrap/Alert';

const InputWithError = ({value, name, onChangeHandler, errorMessage, maxLength, size, type, placeholder}) => (
  <React.Fragment>
    <Form.Control maxLength={maxLength} className="mb-1" name={name} size={size}
      type={type} value={value} onChange={onChangeHandler}
      onBlur={(e) => {
        e.target.value = e.target.value.trim();
        onChangeHandler(e);
      }}
      placeholder={placeholder}/>
    {errorMessage && errorMessage.length > 0 && (
      <Alert variant="danger">{errorMessage}</Alert>
    )}
  </React.Fragment>);

InputWithError.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number]),
  name: PropTypes.string,
  onChangeHandler: PropTypes.func,
  errorMessage: PropTypes.string,
  maxLength: PropTypes.string,
  size: PropTypes.string,
  type: PropTypes.string,
  placeholder: PropTypes.string
};
InputWithError.defaultProps = {
  maxLength: '50',
  type: 'text',
};
export default InputWithError;