import React from 'react';
import PropTypes from 'prop-types';
import {Form, Accordion, Card} from 'react-bootstrap';
import PortionPropertiesTable from './PortionPropertiesTable';
import { PortionPropertiesTableId } from '../utils/generic_utils';
const AdditionalProducts = ({additionalProducts, setAdditionalProducts, formErrors, setFormErrors}) => {

  return (
    <Form.Group>
      <Card>
        <Accordion defaultActiveKey="additional-products-accordion">
          <Accordion.Toggle as={Card.Header} eventKey="additional-products-accordion" className="cursor-pointer">
            <Card.Title>Additional Products</Card.Title>
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="additional-products-accordion">
            <Card.Body>
              <PortionPropertiesTable
                identifierKey={'ean'}
                items={additionalProducts}
                setItems={setAdditionalProducts}
                setFormErrors={setFormErrors}
                formErrors={formErrors}
                tableId={PortionPropertiesTableId.ADDITIONAL_PRODUCTS}
              />
            </Card.Body>
          </Accordion.Collapse>
        </Accordion>
      </Card>
    </Form.Group>
  );
};
AdditionalProducts.propTypes = {
  setAdditionalProducts: PropTypes.func,
  additionalProducts: PropTypes.array,
  formErrors: PropTypes.object,
  setFormErrors: PropTypes.func
};
export default AdditionalProducts;