import {handleFetch, handleSave, HttpMethod, handleUpdate} from './api.js';

const URL = `${process.env.REST_API_BASE}/ops/configuration/menu`;

const getMenu = async () => {
  const data = await handleFetch(URL, HttpMethod.GET);
  return data;

};
const getPortionGroups = async () => {
  const menuData = await getMenu();
  return menuData.menu.portionGroups;
};
const getMenuProductDetails = async (ean, portionGroup) => {
  const data = await handleFetch(`${URL}/portiongroup/${encodeURIComponent(portionGroup)}/product/${ean}`, HttpMethod.GET);
  return data;

};
const updateProduct = async (ean, product, portionGroup) => {
  const data = await handleUpdate(`${URL}/portiongroup/${encodeURIComponent(portionGroup)}/product/${ean}`, {product});
  return data;
};
const saveMenuConfig = async (menuConfiguration) => {
  const savedMenuConfig = await handleSave(URL, {menuConfiguration});
  return savedMenuConfig;
};
const savePortionGroups = async (portionGroups) => {
  const savedPortionGroups = await handleSave(`${URL}/portiongroups`, {portionGroups});
  return savedPortionGroups;
};
const createPortionForPortionGroup = async (product, portionGroup) => {
  const portionGroups = await handleSave(`${URL}/portiongroup/${encodeURIComponent(portionGroup)}/product`, {product});
  return portionGroups;
};
const createPortionGroup = async (portionGroup) => {
  const portionGroups = await handleSave(`${URL}/portiongroup`, {portionGroup});
  return portionGroups;
};
const updatePortionGroup = async(portionGroupName, portionGroupData) => {
  const portionGroup = await handleUpdate(`${URL}/portiongroup/${portionGroupName}`, {portionGroup: portionGroupData});
  return portionGroup;
};
const getPortionGroupByName = async (name) => {
  const data = await handleFetch(`${URL}/portiongroup/${name}`, HttpMethod.GET);
  return data;

};
export default {getMenu, getMenuProductDetails, updateProduct,
  saveMenuConfig, savePortionGroups, createPortionForPortionGroup, createPortionGroup, updatePortionGroup, getPortionGroupByName, getPortionGroups};