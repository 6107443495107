import React from 'react';
import PropTypes from 'prop-types';

const Lander = ({title, children}) => {
  return (
    <div className="lander">
      <h1>{title}</h1>
      {children}
    </div>
  );
};

Lander.propTypes = {
  title: PropTypes.string,
  children: PropTypes.arrayOf(PropTypes.element)
};

export default Lander;