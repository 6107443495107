import {handleFetch, handleSave, HttpMethod} from './api.js';

const getWashTokens = async (userId) => {
  const washTokens = await handleFetch(`${process.env.REST_API_BASE}/ops/v1/carwash/user/${userId}/tokens`, HttpMethod.GET);
  return washTokens;
};
const editToken = async (userId, washToken, used, validTo) => {
  const result = await handleSave(`${process.env.REST_API_BASE}/ops/v1/carwash/user/${userId}/token`, {
    washToken, used, validTo,
  }, false);
  return result;
};
export default {getWashTokens, editToken};
