import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import Form from 'react-bootstrap/Form';
import Navbar from 'react-bootstrap/Navbar';
import { useHistory, useParams, useLocation, Link } from 'react-router-dom';
import { Alert, Button } from 'react-bootstrap';
import get from 'lodash/get';
import PortionGroupForm from '../components/PortionGroupForm';
import api from '../api/menu';
import { useErrorChecker, useDirtyCheck } from '../utils/hooks';
import SaveProgressButton from '../components/SaveProgressButton';
import LoadingSpinner from '../components/LoadingSpinner';
import Lander from '../components/Lander';

const PortionGroupEdit = () => {
  const location = useLocation();
  const {name} = useParams();
  const [error, setError] = useState();
  const [existingPortionGroupNames, setExistingPortionGroupNames] = useState([]);
  const [existingPortionGroupIds, setExistingPortionGroupIds] = useState([]);
  const history = useHistory();
  const [formErrors, setFormErrors] = useState({});
  const hasErrors = useErrorChecker(formErrors);
  useEffect(() => {
    api.getMenu().then(menuData => {
      if(get(menuData, 'menu.portionGroups')) {
        setExistingPortionGroupNames(menuData.menu.portionGroups.map(pg => pg.name));
        setExistingPortionGroupIds(menuData.menu.portionGroups.map(pg => {
          if(pg.name !== name) return pg.id;
        }).filter(id => id));
        const portionGroup = menuData.menu.portionGroups.find(pg => pg.name === name);
        if(portionGroup) {
          setPortionGroup({...portionGroup});
          setInitialData({...portionGroup});
        } else {
          setError(`Portion group with name ${name} not found`);
        }
      } else {
        console.error('invalid menu data:', menuData);
      }
    }).catch(e => {
      console.error(e);
      setError(e.message);
    });
  }, []);
  const [saveInProgress, setSaveInProgress] = useState(false);
  const [portionGroup, setPortionGroup] = useState(location.portionGroup);
  const [initialData, setInitialData] = useState(location.portionGroup);
  const hasChanges = useDirtyCheck(portionGroup, initialData);
  const handleSave = () => {
    setSaveInProgress(true);
    api.updatePortionGroup(name, portionGroup).then(() => {
      setSaveInProgress(false);
      history.push('/menu/portiongroups');
    }).catch(e => console.error('Portion group update failed', e));
  };
  return (
    <React.Fragment>
      {!error && (
        <Navbar bg="light" fixed="bottom">
          <Form inline>
            <SaveProgressButton clickHandler={handleSave} disabled={hasErrors || !hasChanges} saveInProgress={saveInProgress} />
            <Button as={Link} to="/menu/portiongroups" variant="outline-danger" className="ml-1">Cancel</Button>
          </Form>
        </Navbar>
      )}
      <Lander title="Edit Portion Group" />
      {!error && portionGroup && existingPortionGroupNames.length > 0 && existingPortionGroupIds.length > 0 && (
        <PortionGroupForm formErrors={formErrors} setFormErrors={setFormErrors} portionGroup={portionGroup} setPortionGroup={setPortionGroup} existingPortionGroupIds={existingPortionGroupIds} existingPortionGroupNames={existingPortionGroupNames.filter(portionGroupName => portionGroupName !== name)} />
      )}
      {!error && !portionGroup && (existingPortionGroupNames.length === 0 || existingPortionGroupIds.length === 0) && (
        <LoadingSpinner/>
      )}
      {error && (
        <Alert variant="danger">{error}</Alert>
      )}
    </React.Fragment>
  );
};

PortionGroupEdit.propTypes = {
  existingPortionGroupNames: PropTypes.array,
  existingPortionGroupIds: PropTypes.array
};

PortionGroupEdit.defaultProps = {
  existingPortionGroupNames: [],
  existingPortionGroupIds: []
};

export default PortionGroupEdit;