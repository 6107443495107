import React from 'react';
import PropTypes from 'prop-types';
import {Form, Accordion, Card} from 'react-bootstrap';
import PortionPropertiesTable from './PortionPropertiesTable';
import { PortionPropertiesTableId } from '../utils/generic_utils';
const Customizations = ({customizations, setCustomizations, setFormErrors, formErrors}) => {
  return (<Form.Group>
    <Card>
      <Accordion defaultActiveKey="customizations-accordion">
        <Accordion.Toggle as={Card.Header} eventKey="customizations-accordion" className="cursor-pointer">
          <Card.Title>Customizations</Card.Title>
        </Accordion.Toggle>
        <Accordion.Collapse eventKey="customizations-accordion">
          <Card.Body>
            <PortionPropertiesTable
              identifierKey={'id'}
              items={customizations}
              setItems={setCustomizations}
              setFormErrors={setFormErrors}
              formErrors={formErrors}
              tableId={PortionPropertiesTableId.CUSTOMIZATIONS}
            />
          </Card.Body>
        </Accordion.Collapse>
      </Accordion>
    </Card>
  </Form.Group>);
};
Customizations.propTypes = {
  setCustomizations: PropTypes.func,
  customizations: PropTypes.array,
  formErrors: PropTypes.object,
  setFormErrors: PropTypes.func
};
export default Customizations;