import React from 'react';
import PropTypes from 'prop-types';
import InputWithError from './InputWithError';
import Button from 'react-bootstrap/Button';
const ConfigItemRow = ({keyTitle, handleDeleteConfigItem, formErrors, itemKey, itemValue, onChangeHandler, validator, setFormErrors, maxLength, allowDelete}) => {
  return (
    <tr>
      <td>{keyTitle}</td>
      <td><InputWithError value={itemValue}
        maxLength={maxLength}
        name={itemKey}
        onChangeHandler={(e) => {
          if(validator) {
            const error = validator(e.target.value);
            if(setFormErrors) {
              setFormErrors({...formErrors, [itemKey]: error.length > 0 ? {message: error} : undefined});
            }
          }
          onChangeHandler(e);
        }}
        errorMessage={formErrors && formErrors[itemKey] ? formErrors[itemKey].message : ''}
      /></td>
      <td>{allowDelete && <Button variant="outline-danger" onClick={() => handleDeleteConfigItem(itemKey)}>Delete</Button>}</td>
    </tr>
  );
};
ConfigItemRow.propTypes = {
  keyTitle: PropTypes.string.isRequired,
  handleDeleteConfigItem: PropTypes.func.isRequired,
  itemKey: PropTypes.string.isRequired,
  itemValue: PropTypes.string.isRequired,
  onChangeHandler: PropTypes.func.isRequired,
  formErrors: PropTypes.object,
  validator: PropTypes.func,
  setFormErrors: PropTypes.func,
  maxLength: PropTypes.string,
  allowDelete: PropTypes.bool
};
ConfigItemRow.defaultProps = {
  maxLength: '50',
  allowDelete: true
};
export default ConfigItemRow;