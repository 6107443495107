import React from 'react';
import PropTypes from 'prop-types';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';


const AddRow = ({addClickHandler, columns, disabled, size}) => {
  const renderFieldForColumn = (column) => {
    return column.isBoolean
      ? <Form.Check type='checkbox'
        checked={column.value}
        onChange={column.handler} />
      : <Form.Control size={size} style={column.errorMessage && column.errorMessage.length > 0 ? {marginBottom: '5px'}: {}} type={column.isNumber ? 'number' : 'text'} value={column.value} onChange={column.handler} />;
  };
  return (<tr>
    {columns && columns.map((col, index) =>  (
      <td key={index}>
        {renderFieldForColumn(col)}
        {col.errorMessage && col.errorMessage.length > 0 && (
          <Alert variant="danger">{col.errorMessage}</Alert>
        )}
      </td>
    )
    )}
    <td><Button disabled={disabled} variant="outline-success" onClick={addClickHandler} size={size}>Add</Button></td>
  </tr>);
};

AddRow.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.shape({
    handler: PropTypes.func.isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]).isRequired,
  })).isRequired,
  addClickHandler: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  size: PropTypes.string
};
AddRow.defaultProps = {
  disabled: false
};

export default AddRow;