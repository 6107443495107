import React, { useEffect, useState } from 'react';
import api from '../api/washTokens';
import moment from 'moment';
import 'moment-timezone';
import _ from 'lodash';

import { Table, Form, Button, Alert, Popover, OverlayTrigger, ListGroup, InputGroup } from 'react-bootstrap';
import LoadingSpinner from '../components/LoadingSpinner';

const DATE_FORMAT = 'DD.MM.YYYY HH:mm:ss';
const formatDateTime = (dateTime) => dateTime && moment(dateTime).format(DATE_FORMAT);
const formatBoolean = (b) => b === true ? 'Yes' : b === false ? 'No' : '';

const createValidAtPopover = (washToken, validAt) => (
  <Popover>
    <ListGroup>
      {validAt && validAt.map((row, i) => (
        <ListGroup.Item key={`validity_popover_${washToken}_${i}`}>
          {row}
        </ListGroup.Item>
      ))}
    </ListGroup>
  </Popover>
);

const WashTokens = () => {
  const [userId, setUserId] = useState();
  const [washTokens, setWashTokens] = useState([]);
  const [error, setError] = useState();
  const [loading, setLoading] = useState(false);
  const [validToModifications, setValidToModifications] = useState({});

  const updateTokens = async (userId) => {
    setLoading(true);
    return api.getWashTokens(userId).then(data => {
      if (data && data.tokens) {
        setWashTokens(_.orderBy(data.tokens, (e) => (e.endTime || ''), 'desc'));
      }
    }).catch(e => {
      console.error('Wash token fetch failed',e);
      setError(e);
    }).finally(() => setLoading(false));
  };

  useEffect(() => {
    if (userId) {
      setError(null);
      updateTokens(userId);
    }}, [userId]);

  const handleSave = (washToken, used, validTo) => {
    setLoading(true);
    setError(null);
    api.editToken(userId, washToken, used, validTo)
      .then(() => updateTokens(userId))
      .catch(e => setError(e))
      .finally(() => setLoading(false));
  };


  const createValidTo = (tokenData) => {
    const validToFormatted = formatDateTime(tokenData.validTo);
    const areaPopover = createValidAtPopover(tokenData.washToken, tokenData.validAt);
    const date = validToModifications[tokenData.washToken];
    const modifyPopover = (<Popover container="body">
      <Form.Group>
        <InputGroup className="mb-1">
          <Form.Control className="mb-1" name={name} defaultValue={validToFormatted}
            type={'text'}
            onChange={(e) => {
              setValidToModifications({
                ...validToModifications,
                [tokenData.washToken]: e.target.value.trim(),
              });
            }}/>
          <Button
            disabled={!date || !moment(date, DATE_FORMAT, true).isValid()}
            onClick={() => {
              handleSave(tokenData.washToken, undefined, moment(date, DATE_FORMAT, true).format());
              setValidToModifications({
                ...validToModifications,
                [tokenData.washToken]: undefined,
              });
            }}>
            Save
          </Button>
        </InputGroup>
      </Form.Group>
    </Popover>);

    return (
      <>
        {validToFormatted}
        <OverlayTrigger trigger="click" placement="right" overlay={modifyPopover}>
          <Button>Modify</Button>
        </OverlayTrigger>
        <OverlayTrigger trigger="click" placement="right" overlay={areaPopover}>
          <Button variant="success">Area</Button>
        </OverlayTrigger>
      </>
    );
  };

  return (
    <React.Fragment>
      <Form.Group controlId="formGroupName">
        <Form.Label>Name</Form.Label>
        <InputGroup className="mb-1">
          <Form.Control maxLength={14} className="mb-1" name={name}
            type={'text'}
            onBlur={(e) => {
              e.target.value = e.target.value.trim();
              setUserId(e.target.value);
            }}
            placeholder={'Search by user id'}/>
          <Button>Search</Button>
        </InputGroup>
      </Form.Group>
      {error && (
        <Alert variant="danger">
          <Alert.Heading>{error && error.msg}</Alert.Heading>
          {error && error.message}
        </Alert>
      )}
      {loading && (<LoadingSpinner/>)}
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Wash token</th>
            <th>Bought at</th>
            <th>Bought on</th>
            <th>Product</th>
            <th>Used</th>
            <th>Valid to</th>
            <th>Used at</th>
            <th>Used on</th>
            <th>Type</th>
            <th>Remote</th>
            <th>Prepurchase</th>
            <th>Cost</th>
          </tr>
        </thead>
        <tbody>
          {washTokens.map((washToken, i) => <tr key={`wash_token_table_${i}`}>
            <td>{washToken.washToken}</td>
            <td>{washToken.stationName}</td>
            <td>{formatDateTime(washToken.endTime)}</td>
            <td>{washToken.productName}</td>
            <td>{formatBoolean(washToken.used)} <Button onClick={() => handleSave(washToken.washToken, !washToken.used, undefined)}>Toggle</Button></td>
            <td>{createValidTo(washToken)}</td>
            <td>{washToken.usedStationName}</td>
            <td>{formatDateTime(washToken.usedTimestamp)}</td>
            <td>{washToken.productType}</td>
            <td>{formatBoolean(washToken.remote)}</td>
            <td>{formatBoolean(washToken.prepurchase)}</td>
            <td>{washToken.totalAmount}</td>
          </tr>)}
        </tbody>
      </Table>
    </React.Fragment>
  );
};

export default WashTokens;
