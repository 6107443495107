import React from 'react';
import PropTypes from 'prop-types';
import Button from 'react-bootstrap/Button';

const ListItem = ({pobId, name, disabled, deleteHandler, toggleDisabledHandler}) => {
  const renderToggleButton = () => {
    if(!disabled) {
      return <Button variant="outline-danger" className="disable-button" onClick={() => {
        toggleDisabledHandler(pobId);
      }}>Disable</Button>;
    } else return <Button variant="outline-success" onClick={() => toggleDisabledHandler(pobId)}>Enable</Button>;

  };
  return (
    <tr>
      <td>{(!disabled).toString()}</td>
      <td>{pobId}</td>
      <td>{name}</td>
      <td>
        {renderToggleButton()}
        <Button variant="danger" className="ml-1 delete-button" onClick={() => deleteHandler(pobId)}>Delete</Button>
      </td>
    </tr>
  );};

ListItem.propTypes = {
  pobId: PropTypes.string,
  name: PropTypes.string,
  disabled: PropTypes.bool,
  deleteHandler: PropTypes.func,
  toggleDisabledHandler: PropTypes.func
};
export default ListItem;