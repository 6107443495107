import { getGraphAccessToken, adalApiFetch, adalAcquireTokenRedirect} from '../../adal-config';

export const handleFetch = async (url, method, body, json = true) => {
  return getGraphAccessToken().then(() => {
    return adalApiFetch(fetch, url,
      {
        method,
        headers: {
          'Content-Type': 'application/json',
        },
        ...(body ? {body} : {})
      }).then(data => {
      if (json) {
        return data.json().then(dataJSON => {
          if(data.status !== 200) throw new Error(dataJSON.message);
          return dataJSON;
        });
      }
      else {
        if(data.status !== 200) throw new Error(data.body);
      }
    });
  }).catch(error => {

    console.error('ADAL getToken() failed: ', error);
    if (error.message.match(/AADSTS50058/)) {
      adalAcquireTokenRedirect();
    }
    throw error;
  });
};
export const handleSave = async (url, payload, json = true) => {
  const data = await handleFetch(url, HttpMethod.POST, JSON.stringify(payload), json);
  return data;
};
export const handleUpdate = async (url, payload) => {
  const data = await handleFetch(url, HttpMethod.PUT, JSON.stringify(payload));
  return data;
};
export const handleDelete = async (url, pobId) => {
  const data = await handleFetch(`${url}/${pobId}`, HttpMethod.DELETE);
  return data;
};
export const HttpMethod = {
  DELETE: 'DELETE',
  GET: 'GET',
  POST: 'POST',
  PUT: 'PUT'
};