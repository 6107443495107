import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import BootstrapTable from 'react-bootstrap/Table';
import AddRow from '../components/AddRow';
import { parseValue, FORM_FIELDS } from '../utils/form_utils';
import PortionPropertiesTableRow from '../components/PortionPropertiesTableRow';
import { getUniqueKeysWithTypes, getFallbackKeys, getFieldDefaultValue, ERROR_MESSAGES } from '../utils/generic_utils';
import uniqBy from 'lodash/uniqBy';

const getConfigKeyReducer = (tableId) => (acc,cur) => {
  acc[cur.name] = getFieldDefaultValue(cur, tableId);
  return acc;
};

const PortionPropertiesTable = ({items, setItems, formErrors, setFormErrors, identifierKey, hasCollapse, size, tableId}) => {
  const [keys, setKeys] = useState(getFallbackKeys(tableId));
  const [addRowErrors, setAddRowErrors] = useState({});
  useEffect(() => {
    if(items && items.length > 0) {
      const keysWithTypes = uniqBy([...keys, ...getUniqueKeysWithTypes(items)], 'name');
      setKeys(keysWithTypes);
      setNewConfig(keysWithTypes.reduce(getConfigKeyReducer(tableId), {}));
    }
  }, []);
  const [newConfig, setNewConfig] = useState(keys.reduce(getConfigKeyReducer(tableId), {}));
  const resetAddField = () => {
    setNewConfig({...keys.reduce(getConfigKeyReducer(tableId), {})});
  };
  const handleAddItem = () => {
    const parsedConfig = Object.keys(newConfig).reduce((obj,key) => {
      // do not parse ean or id, those exist as strings in db, regardless of value type
      obj[key] = key !== identifierKey ? parseValue(typeof newConfig[key] !== 'boolean' ? newConfig[key].trim() : newConfig[key]) : newConfig[key].trim();
      return obj;
    }, {});
    const emptyRequiredKeys = getEmptyRequiredValueKeys(parsedConfig);
    const hasDuplicateIdentifier = items.find(item => item[identifierKey] === parsedConfig[identifierKey]) !== undefined;
    setAddRowErrors({
      ...addRowErrors,
      ...clearedAddRowErrors(),
      ...newAddRowErrors(emptyRequiredKeys),
      ...(hasDuplicateIdentifier ? {[`${tableId}_${identifierKey}_new`] : {message: ERROR_MESSAGES.IDENTIFIER_EXISTS}} : {})
    });
    if(emptyRequiredKeys.length === 0 && !hasDuplicateIdentifier) {
      const finalConfig = validateDefaultProperty(parsedConfig, items);

      if(finalConfig.default === true && items.find(item => item.default === true)) {
        // if the new item has been configured as default, set every other item's default to false
        setItems([...items.map(item => ({...item, default: false})), finalConfig]);
      } else {
        setItems([...(items || []), finalConfig]);
      }
      resetAddField();
    }
  };
  const validateDefaultProperty = (parsedConfig, items) => {
    // ensure that we always have one item with default
    const clonedConfig = {...parsedConfig};
    const configHasDefaultProperty = Object.prototype.hasOwnProperty.call(clonedConfig, FORM_FIELDS.DEFAULT);
    const currentDefaultCount = items.filter(item => Object.prototype.hasOwnProperty.call(item, FORM_FIELDS.DEFAULT) && item.default === true).length;
    const noDefaultFound = currentDefaultCount === 0;
    if(configHasDefaultProperty && clonedConfig.default !== true && noDefaultFound) {
      clonedConfig.default = true;
      return clonedConfig;
    }

    return parsedConfig;
  };
  const getEmptyRequiredValueKeys = (newConfig) => {
    const emptyRequiredKeys = Object.keys(newConfig).length > 0 && Object.keys(newConfig).filter(configKey => validateKey(configKey));
    return emptyRequiredKeys;
  };
  const newAddRowErrors = (emptyRequiredKeys) => {
    return emptyRequiredKeys.reduce((obj, key) => {
      obj[`${tableId}_${key}_new`] = {message: ERROR_MESSAGES.REQUIRED_FIELD};
      return obj;
    }, {});
  };
  const clearedAddRowErrors = () => {
    return Object.keys(newConfig).reduce((obj, key) => {
      obj[`${tableId}_${key}_new`] = undefined;
      return obj;
    }, {});
  };
  const validateKey = (configKey) => {
    const correspondingTableKey = keys.find(key => key.name === configKey);
    const keyIsRequiredWithEmptyValue = correspondingTableKey && correspondingTableKey.required && newConfig[configKey].toString().trim().length === 0;
    return keyIsRequiredWithEmptyValue;
  };
  const handleDeleteItem = (identifierValue) => {
    setItems([...items.filter(product => product[identifierKey] !== identifierValue)]);
  };
  const handleUpdateItems  = (identifierValue, e, isBoolean = false, isNumber = false) => {
    const value = e.target.value;
    const fieldName = e.target.name;
    if(isBoolean) {
      // ensure that we always have only one item with default
      setItems(fieldName === FORM_FIELDS.DEFAULT
        ? items.map(product => product[identifierKey] === identifierValue ? {...product, [fieldName]: true} : {...product, [fieldName]: false})
        : items.map(product => product[identifierKey] === identifierValue ? {...product, [e.target.name]: !product[e.target.name]} : {...product}));
    } else {
      setItems(items.map(product => product[identifierKey] === identifierValue ? {...product, [e.target.name]: isNumber ? parseValue(value) : value } : {...product}));
    }
  };

  return (
    <BootstrapTable striped bordered hover size={size}>
      <thead>
        <tr>
          {keys.map(key => <th key={key.name}>{key.name}</th>)}
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>
        {items && items.map((item) =>
          <PortionPropertiesTableRow key={item[identifierKey]} item={item}
            onChangeHandler={handleUpdateItems}
            deleteHandler={handleDeleteItem}
            keys={keys}
            identifierKey={identifierKey}
            setFormErrors={setFormErrors}
            formErrors={formErrors}
            collapseKey={hasCollapse ? item[identifierKey] : false}
            size={size}
          />
        )}
        <AddRow columns={Object.keys(newConfig)
          .map(key => {
            const fallbackKey = getFallbackKeys(tableId).find(tableKey => tableKey.name === key);
            const isBoolean = fallbackKey.type === 'boolean';
            const isNumber = fallbackKey.type === 'number';
            return ({
              value: newConfig[key],
              handler: (e) => {
                isBoolean ? setNewConfig({...newConfig, [key]: !newConfig[key]}) : setNewConfig({...newConfig, [key]: e.target.value});
              },
              errorMessage: addRowErrors[`${tableId}_${key}_new`] && addRowErrors[`${tableId}_${key}_new`].message,
              isBoolean,
              isNumber
            });
          }
          )}
        addClickHandler={handleAddItem}
        size={size}
        />
      </tbody>

    </BootstrapTable>
  );
};

PortionPropertiesTable.propTypes = {
  items: PropTypes.array,
  setItems: PropTypes.func,
  formErrors: PropTypes.object,
  setFormErrors: PropTypes.func,
  identifierKey: PropTypes.string,
  hasCollapse: PropTypes.bool,
  size: PropTypes.string,
  tableId: PropTypes.string.isRequired
};
PortionPropertiesTable.defaultProps = {
  items: []
};

export default PortionPropertiesTable;