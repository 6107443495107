import React from 'react';
import PropTypes from 'prop-types';
import { Form, Alert } from 'react-bootstrap';
import InputWithError from '../components/InputWithError';
import SimpleAddList from '../components/SimpleAddList';
import CustomizationChoicesWithCount from './CustomizationChoicesWithCount';
import CustomizationChoices from './CustomizationChoices';
import CustomizationGroups from './CustomizationGroups';
import AdditionalProducts from './AdditionalProducts';
import Customizations from './Customizations';
import { FORM_FIELDS, validateLength, validateDateInput } from '../utils/form_utils';
import { Typeahead } from 'react-bootstrap-typeahead';
const ProductForm = ({product, formErrors, setFormErrors, setProduct, portionGroup, portionGroupNames, disableEAN, setPortionGroup}) => {
  const takeawayEAN = product && product.takeaway ? product.takeaway.ean : undefined;
  const takeawayReceiptName = product && product.takeaway ? product.takeaway.receiptName : undefined;
  const showTakeawayFields = product.takeaway || product.type === 'PIZZA';
  const REQUIRED_FIELDS = [FORM_FIELDS.EAN, FORM_FIELDS.NAME, FORM_FIELDS.RECEIPT_NAME];
  const handleInputChange = (e) => {
    const target = e.target;
    const value =  e.target.value;
    const fieldName = target.name;
    const error = REQUIRED_FIELDS.includes(fieldName) && validateLength(value);
    if(setFormErrors) {
      setFormErrors({...formErrors, [fieldName]: error.length > 0 ? {message: error} : undefined});
    }
    switch(fieldName) {
    case FORM_FIELDS.EAN: {
      setProduct({...product, ean: value.replace(/ /g, '')});
      break;
    }
    case FORM_FIELDS.NAME: {
      setProduct({...product, name: value});
      break;
    }
    case FORM_FIELDS.RECEIPT_NAME:
      setProduct({...product, receiptName: value});
      break;
    case FORM_FIELDS.TYPE:
      setProduct({...product, type: value});
      break;
    case FORM_FIELDS.CART_PRIORITY: {
      if(value === '' || (parseInt(value) >= 0 && parseInt(value) <= 9))
        setProduct({...product, cartPriority: value !== '' ? parseInt(value) : undefined});
      break;
    }
    case FORM_FIELDS.EXCLUDED_FROM_EMPLOYEE_DISCOUNT:
      setProduct({...product, excludedFromEmployeeDiscount: !product.excludedFromEmployeeDiscount});
      break;
    case FORM_FIELDS.SHOW_NUTRIENTS:
      setProduct({...product, showNutrients: !product.showNutrients});
      break;
    case FORM_FIELDS.VALID_FROM:
    case FORM_FIELDS.VALID_TO: {
      const dateValidationError = validateDateInput(value);
      if(setFormErrors) {
        setFormErrors({...formErrors, [fieldName]: dateValidationError.length > 0 ? {message: dateValidationError} : undefined});
      }
      setProduct({...product, [fieldName]: value !== '' ? value : undefined });
      break;
    }
    case FORM_FIELDS.TAKEAWAY_EAN:
      setProduct({...product, takeaway: {...product.takeaway, ean: value !== '' ? value.replace(/ /g, '') : undefined}});
      break;
    case FORM_FIELDS.TAKEAWAY_RECEIPT_NAME:
      setProduct({...product, takeaway: {...product.takeaway, receiptName: value !== '' ? value : undefined}});
      break;
    }
  };
  const handleDeleteOverride = (ean, errorFieldName) => {
    setFormErrors({...formErrors, [errorFieldName]: undefined});
    const newOverrides = product.overrides.filter(overrideEan => {
      return overrideEan !== ean;
    });
    setProduct({...product, overrides: newOverrides && newOverrides.length > 0 ? newOverrides : undefined});
  };
  const handleAddOverride = (ean) => {
    setProduct({...product, overrides: [...product.overrides || [], ean]});
  };
  const handleSetOverrides = (ean, idx) => {
    setProduct({...product, overrides: product.overrides.map((overrideEan, index) => {
      if(index === idx) return ean;
      return overrideEan;
    })});
  };
  const setCustomizationChoices = (customizationChoices) => {
    setProduct({...product, customizationChoices: customizationChoices && customizationChoices.length > 0 ? [...customizationChoices] : undefined});
  };
  const setAdditionalProducts = (additionalProducts) => {
    setProduct({...product, additionalProducts: additionalProducts && additionalProducts.length > 0 ? [...additionalProducts] : undefined});
  };
  const setCustomizations = (customizations) => {
    setProduct({...product, customizations: customizations && customizations.length > 0 ? [...customizations] : undefined});

  };
  const setCustomizationChoicesWithCount = (customizationChoicesWithCount) => {
    setProduct({...product, customizationChoicesWithCount: customizationChoicesWithCount && customizationChoicesWithCount.length > 0 ? [...customizationChoicesWithCount] : undefined});
  };
  const setCustomizationsGroups = (customizationsGroups) => {
    setProduct({...product, customizationsGroups: customizationsGroups && customizationsGroups.length > 0 ? [...customizationsGroups] : undefined});
  };
  const portionGroupHandler = values => {
    const [portionGroup] = values;
    setFormErrors({...formErrors, portionGroup: values.length > 0 ? undefined : {message: 'Portion group cannot be empty.'}});
    setPortionGroup(portionGroup);

  };
  return (
    <Form>
      {portionGroupNames && (
        <Form.Group controlId="formGroupPortionGroup">
          <Form.Label>Portion Group</Form.Label>
          <Typeahead
            id="portiongroup-typeahead"
            clearButton
            onInputChange={(value) => portionGroupHandler(value)}
            onChange={portionGroupHandler}
            options={portionGroupNames || []}
            placeholder={portionGroupNames.length > 0 ? 'Choose a portion group...' : 'Fetching portion groups...'}
            defaultSelected={[portionGroup || '']}
          />
          {formErrors[FORM_FIELDS.PORTION_GROUP] && formErrors[FORM_FIELDS.PORTION_GROUP].message && (
            <Alert className="mt-1" variant="danger">{formErrors[FORM_FIELDS.PORTION_GROUP].message}</Alert>
          )}

        </Form.Group>
      )}
      <Form.Group controlId="formGroupEan">
        <Form.Label>EAN</Form.Label>
        {disableEAN && (
          <Form.Control value={product.ean} readOnly></Form.Control>
        )}
        {!disableEAN && (
          <InputWithError value={product.ean || ''} name={FORM_FIELDS.EAN} onChangeHandler={handleInputChange}
            errorMessage={formErrors[FORM_FIELDS.EAN] ? formErrors[FORM_FIELDS.EAN].message : ''}/>
        )}

      </Form.Group>

      {showTakeawayFields && (
        <Form.Group controlId="formGroupTakeawayEan">
          <Form.Label>Takeaway EAN</Form.Label>
          <Form.Control value={takeawayEAN || ''} name={FORM_FIELDS.TAKEAWAY_EAN} onChange={handleInputChange}></Form.Control>
        </Form.Group>
      )}

      <Form.Group controlId="formGroupReceiptName">
        <Form.Label>Receipt name</Form.Label>
        <InputWithError value={product.receiptName || ''} name={FORM_FIELDS.RECEIPT_NAME} onChangeHandler={handleInputChange}
          errorMessage={formErrors[FORM_FIELDS.RECEIPT_NAME] ? formErrors[FORM_FIELDS.RECEIPT_NAME].message : ''} />
      </Form.Group>

      {showTakeawayFields && (
        <Form.Group controlId="formGroupTakeawayReceiptName">
          <Form.Label>Takeaway receipt name</Form.Label>
          <Form.Control value={takeawayReceiptName || ''} name={FORM_FIELDS.TAKEAWAY_RECEIPT_NAME} onChange={handleInputChange}></Form.Control>
        </Form.Group>
      )}

      <Form.Group controlId="formGroupName">
        <Form.Label>Name</Form.Label>
        <InputWithError value={product.name || ''} name={FORM_FIELDS.NAME} onChangeHandler={handleInputChange}
          errorMessage={formErrors[FORM_FIELDS.NAME] ? formErrors[FORM_FIELDS.NAME].message : ''} />
      </Form.Group>
      <Form.Group controlId="formGroupType">
        <Form.Label>Type</Form.Label>
        <InputWithError value={product.type || ''} name={FORM_FIELDS.TYPE} onChangeHandler={handleInputChange}
          errorMessage={formErrors[FORM_FIELDS.TYPE] ? formErrors[FORM_FIELDS.TYPE].message : ''} />
      </Form.Group>
      <Form.Group controlId="formGroupCartPriority">
        <Form.Label>Cart Priority</Form.Label>
        <InputWithError value={product.cartPriority !== undefined ? product.cartPriority : ''} name={FORM_FIELDS.CART_PRIORITY} onChangeHandler={handleInputChange}
          placeholder="Cart priority number (0-9)"
          type="number"
          errorMessage={formErrors[FORM_FIELDS.CART_PRIORITY] ? formErrors[FORM_FIELDS.CART_PRIORITY].message : ''} />
      </Form.Group>
      <Form.Group controlId="formGroupExcludedFromEmployeeDiscount">
        <Form.Check type="checkbox" name={FORM_FIELDS.EXCLUDED_FROM_EMPLOYEE_DISCOUNT}
          label="Excluded from employee discount" checked={product.excludedFromEmployeeDiscount || false} onChange={handleInputChange}/>
      </Form.Group>
      <Form.Group controlId="formGroupShowNutrients">
        <Form.Check type="checkbox" name={FORM_FIELDS.SHOW_NUTRIENTS}
          label="Show nutrients" checked={product.showNutrients || false} onChange={handleInputChange}/>
      </Form.Group>

      <Form.Group controlId="formGroupValidFrom" className="w-50">
        <Form.Label>Valid From</Form.Label>
        <InputWithError value={product.validFrom || ''} name={FORM_FIELDS.VALID_FROM} onChangeHandler={handleInputChange}
          errorMessage={formErrors[FORM_FIELDS.VALID_FROM] ? formErrors[FORM_FIELDS.VALID_FROM].message : ''} maxLength="10" />
      </Form.Group>
      <Form.Group controlId="formGroupValidTo" className="w-50">
        <Form.Label>Valid To</Form.Label>
        <InputWithError value={product.validTo || ''} name={FORM_FIELDS.VALID_TO} onChangeHandler={handleInputChange}
          errorMessage={formErrors[FORM_FIELDS.VALID_TO] ? formErrors[FORM_FIELDS.VALID_TO].message : ''} maxLength="10" />
      </Form.Group>
      <Form.Group controlId="formGroupOverrides">
        <Form.Label>Overrides</Form.Label>
        <SimpleAddList keyName="overrides" validator={validateLength} onChangeHandler={handleSetOverrides}
          values={product.overrides} deleteHandler={handleDeleteOverride} addHandler={handleAddOverride} maxLength="50"
          formErrors={formErrors} setFormErrors={setFormErrors} />
      </Form.Group>

      <CustomizationChoicesWithCount
        setCustomizationChoicesWithCount={setCustomizationChoicesWithCount}
        customizationChoicesWithCount={product.customizationChoicesWithCount}
        formErrors={formErrors}
        setFormErrors={setFormErrors}
      />

      <CustomizationChoices
        setCustomizationChoices={setCustomizationChoices}
        customizationChoices={product.customizationChoices}
        formErrors={formErrors}
        setFormErrors={setFormErrors}
      />

      <CustomizationGroups
        setCustomizationsGroups={setCustomizationsGroups}
        customizationsGroups={product.customizationsGroups}
        formErrors={formErrors} setFormErrors={setFormErrors}
      />

      <AdditionalProducts
        additionalProducts={product.additionalProducts}
        setAdditionalProducts={setAdditionalProducts}
        setFormErrors={setFormErrors}
        formErrors={formErrors}
      />

      <Customizations
        setCustomizations={setCustomizations}
        customizations={product.customizations}
        setFormErrors={setFormErrors}
        formErrors={formErrors}
      />
    </Form>
  );

};
ProductForm.propTypes = {
  product: PropTypes.object,
  formErrors: PropTypes.object,
  setFormErrors: PropTypes.func,
  setProduct: PropTypes.func,
  portionGroup: PropTypes.string,
  portionGroupNames: PropTypes.array,
  disableEAN: PropTypes.bool,
  setPortionGroup: PropTypes.func
};
ProductForm.defaultProps = {
  disableEAN: true
};
export default ProductForm;