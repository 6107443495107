import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import {runWithAdal} from 'react-adal';
import { authContext } from '../adal-config';
import App from './App.js';


const DO_NOT_LOGIN = false;
runWithAdal(authContext, () => {
  ReactDOM.render(
    <Router>
      <App />
    </Router>, document.getElementById('root')
  );
}, DO_NOT_LOGIN);
