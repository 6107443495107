import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-bootstrap';
import InputWithError from './InputWithError';
import { FORM_FIELDS, validateLength } from '../utils/form_utils';
const PortionGroupForm = ({formErrors, portionGroup, setFormErrors, existingPortionGroupNames, existingPortionGroupIds, setPortionGroup}) => {
  const REQUIRED_FIELDS = [FORM_FIELDS.NAME, FORM_FIELDS.CATEGORY, FORM_FIELDS.ID];
  const inputHandler = (e) => {
    const fieldName = e.target.name;
    const value = e.target.value;
    switch(fieldName) {
    case FORM_FIELDS.ID: {
      const idLengthError = validateLength(value);
      const idIsUnique = !existingPortionGroupIds.includes(value);
      if(setFormErrors) {
        setFormErrors({...formErrors, [fieldName]: !idIsUnique ? {message: 'ID must be unique.'} : idLengthError.length > 0 ? {message: idLengthError} : undefined});
      }
      setPortionGroup({...portionGroup, [fieldName]: value});
      break;
    }
    case FORM_FIELDS.NAME: {
      const nameLengthError = validateLength(value);
      const nameIsUnique = !existingPortionGroupNames.includes(value);
      if(setFormErrors) {
        setFormErrors({...formErrors, [fieldName]: !nameIsUnique ? {message: 'Name must be unique.'} : nameLengthError.length > 0 ? {message: nameLengthError} : undefined});
      }
      setPortionGroup({...portionGroup, [fieldName]: value});
      break;
    }
    case FORM_FIELDS.CART_PRIORITY: {
      if(value === '' || (parseInt(value) >= 0 && parseInt(value) <= 9))
        setPortionGroup({...portionGroup, cartPriority: value !== '' ? parseInt(value) : undefined});
      break;
    }
    case FORM_FIELDS.EXTRA_DRINK_NOT_ALLOWED: {
      setPortionGroup({...portionGroup, extraDrinkNotAllowed: !portionGroup.extraDrinkNotAllowed});
      break;
    }
    case FORM_FIELDS.MENU_CONTENT_DESCRIPTION: {
      setPortionGroup({...portionGroup, menuContentDescription: value !== '' ? value : undefined});
      break;
    }
    default: {
      if(REQUIRED_FIELDS.includes(fieldName)) {
        const error = validateLength(value);
        if(setFormErrors) {
          setFormErrors({...formErrors, [fieldName]: error.length > 0 ? {message: error} : undefined});
        }
      }
      setPortionGroup({...portionGroup, [fieldName]: value});
    }
    }
  };
  return (<Form>
    <Form.Group controlId="formGroupId">
      <Form.Label>ID</Form.Label>
      <InputWithError value={portionGroup.id || ''}
        placeholder="Unique identifier for the portion group"
        name={FORM_FIELDS.ID}
        onChangeHandler={inputHandler}
        errorMessage={formErrors[FORM_FIELDS.ID] ? formErrors[FORM_FIELDS.ID].message : ''}
      />
    </Form.Group>
    <Form.Group controlId="formGroupCategory">
      <Form.Label>Category</Form.Label>
      <InputWithError value={portionGroup.category || ''}
        placeholder="Category of the portion group (e.g. A_LA_CARTE)"
        name={FORM_FIELDS.CATEGORY}
        onChangeHandler={inputHandler}
        errorMessage={formErrors[FORM_FIELDS.CATEGORY] ? formErrors[FORM_FIELDS.CATEGORY].message : ''}
      />
    </Form.Group>
    <Form.Group controlId="formGroupName">
      <Form.Label>Name</Form.Label>
      <InputWithError value={portionGroup.name || ''}
        placeholder="Unique name of the portion group"
        name={FORM_FIELDS.NAME}
        onChangeHandler={inputHandler}
        errorMessage={formErrors[FORM_FIELDS.NAME] ? formErrors[FORM_FIELDS.NAME].message : ''}
      />
    </Form.Group>
    <Form.Group controlId="formGroupCartPriority">
      <Form.Label>Cart Priority</Form.Label>
      <InputWithError value={portionGroup.cartPriority !== undefined ? portionGroup.cartPriority : ''}
        placeholder="Cart priority number (0-9)"
        name={FORM_FIELDS.CART_PRIORITY}
        type="number"
        onChangeHandler={inputHandler}
        errorMessage={formErrors[FORM_FIELDS.CART_PRIORITY] ? formErrors[FORM_FIELDS.CART_PRIORITY].message : ''}
      />
    </Form.Group>
    <Form.Group controlId="formGroupMenuContentDescription">
      <Form.Label>Menu Content Description</Form.Label>
      <InputWithError value={portionGroup.menuContentDescription || ''}
        placeholder="i18n variable name string"
        name={FORM_FIELDS.MENU_CONTENT_DESCRIPTION}
        onChangeHandler={inputHandler}
        errorMessage={formErrors[FORM_FIELDS.MENU_CONTENT_DESCRIPTION] ? formErrors[FORM_FIELDS.MENU_CONTENT_DESCRIPTION].message : ''}
      />
    </Form.Group>
    <Form.Group controlId="formExtraDrinkNotAllowed">
      <Form.Check type="checkbox" name="extraDrinkNotAllowed" label="Allow extra drinks" checked={!portionGroup.extraDrinkNotAllowed} onChange={inputHandler}/>
    </Form.Group>
  </Form>);
};

PortionGroupForm.propTypes = {
  formErrors: PropTypes.object,
  portionGroup: PropTypes.object,
  setFormErrors: PropTypes.func,
  existingPortionGroupNames: PropTypes.array,
  existingPortionGroupIds: PropTypes.array,
  setPortionGroup: PropTypes.func
};
PortionGroupForm.defaultProps = {
  existingPortionGroupNames: [],
  existingPortionGroupIds: []
};

export default PortionGroupForm;