import React, {useState} from 'react';
import PropTypes from 'prop-types';
import WhitelistTable from '../components/WhitelistTable';
import InputGroup from 'react-bootstrap/InputGroup';
import FormControl from 'react-bootstrap/FormControl';
import Button from 'react-bootstrap/Button';
import {Typeahead} from 'react-bootstrap-typeahead';
import Alert from 'react-bootstrap/Alert';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import LoadingSpinner from './LoadingSpinner';
import Lander from './Lander';

const Whitelist = ({toggleDisabledHandler, onChangeHandler, data, addNewHandler, title, allStations, error, newPobId, deleteHandler}) => {
  const [typeaheadRef, setTypeaheadRef] = useState();
  const [searchTerm, setSearchTerm] = useState('');
  return (
    <div className="Buffets">
      <Lander title={title}/>
      {!error && data && data.whitelist && (
        <div>
          <div>
            <InputGroup className="mb-3">
              <FormControl
                type="text"
                onChange={(e) => {
                  setSearchTerm(e.target.value);
                }}
                placeholder="Search by pobId or name..."
                aria-label="Search"
              />
            </InputGroup>
            <InputGroup className="mb-1">
              <Typeahead
                id="whitelist-typeahead"
                clearButton
                ref={typeahead => setTypeaheadRef(typeahead)}
                labelKey={(option) => `${option.pobId} ${option.name}`}
                onChange={onChangeHandler}
                options={allStations && Object.keys(allStations).length > 0 && data && data.whitelist ? Object.keys(allStations).filter(pobId => !data.whitelist.map(station => station.pobId).includes(pobId)).map(pobId => ({pobId: pobId, name: allStations[pobId] || 'Name not found'})) : []}
                placeholder="Choose a station..."
              />
              <InputGroup.Append>
                <Button disabled={newPobId === undefined || newPobId.length === 0} className="mb-1" variant="success" onClick={() => {
                  addNewHandler(typeaheadRef);
                }}>Add</Button>
              </InputGroup.Append>
            </InputGroup>
          </div>
          <WhitelistTable deleteHandler={deleteHandler} data={data.whitelist.filter(station => station.pobId.indexOf(searchTerm) > -1  || (allStations && allStations[station.pobId] && allStations[station.pobId].toUpperCase().indexOf(searchTerm.toUpperCase()) > -1) ) || []} toggleDisabledHandler={toggleDisabledHandler} allStations={allStations}/>
        </div>
      )}
      {!data && !error && (
        <LoadingSpinner />
      )}
      {error && (
        <Alert variant="danger">
          <Alert.Heading>{error && error.msg}</Alert.Heading>
          {error && error.message}
        </Alert>
      )}
    </div>
  );
};

Whitelist.propTypes = {
  deleteHandler: PropTypes.func,
  onChangeHandler: PropTypes.func,
  toggleDisabledHandler: PropTypes.func,
  addNewHandler: PropTypes.func,
  data: PropTypes.shape({
    whitelist: PropTypes.array,
  }),
  title: PropTypes.string,
  allStations: PropTypes.object,
  error: PropTypes.object,
  newPobId: PropTypes.string,
};
export default Whitelist;