import React, {useEffect, useState} from 'react';
import api from '../api/stations';
import FoodStationsConfigTable from '../components/FoodStationsConfigTable';
import LoadingSpinner from '../components/LoadingSpinner';
import { Alert } from 'react-bootstrap';
import Lander from '../components/Lander';

const FoodStationsConfiguration = () => {
  const [stationsData, setStationsData] = useState();
  const [showSpinner, setShowSpinner] = useState(false);
  const [error, setError] = useState();
  useEffect(() => {
    let isMounted = true;
    api.getStations().then(data => {
      if(isMounted) {
        setStationsData(data);
      }
    }).catch(e => {
      console.error('STATIONS FETCH FAILED', e);
      setError(e);
    });
    return () => {
      isMounted = false;
    };
  }, []);
  const handleSave = (callback) => {
    setShowSpinner(true);
    api.saveFoodStationsConfig(stationsData).then(res => {
      setStationsData({...res});
      setShowSpinner(false);
      callback(res);
    }).catch(e => console.error(e));
  };
  return (<React.Fragment>
    <Lander title="Food Stations Configuration" />
    {stationsData && !error && (
      <FoodStationsConfigTable showSpinner={showSpinner} title="Configuration" stationsData={stationsData} setStationsData={setStationsData} saveHandler={handleSave}/>
    )}
    {!stationsData && !error && (
      <LoadingSpinner />
    )}
    {error && (
      <Alert variant="danger">
        <Alert.Heading>{error && error.msg}</Alert.Heading>
        {error && error.message}
      </Alert>
    )}

  </React.Fragment>);
};
export default FoodStationsConfiguration;
