import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import api from '../api/whitelists';
import Whitelist from '../components/Whitelist';
import { useDirtyCheck } from '../utils/hooks';

import { getNameForWhitelistDomain } from '../utils/generic_utils';
import { Navbar, Form } from 'react-bootstrap';
import SaveProgressButton from '../components/SaveProgressButton';

const WhitelistContainer = props => {
  const {env, domain} = props;
  const [data, setData] = useState(undefined);
  const [newPobId, setNewPobId] = useState('');
  const [error, setError] = useState();
  const [saveInProgress, setSaveInProgress] = useState(false);
  const [initialData, setInitialData] = useState();
  const canBeSaved = useDirtyCheck(data,initialData);

  useEffect(() => {
    let isMounted = true;
    api.getWhitelist(env, domain).then(data => {
      if(isMounted) {
        setData(data);
        setInitialData(data);
      }
    }).catch(e => {
      console.error('Whitelist fetch failed',e);
      setError(e);
    });
    return () => {
      isMounted = false;
    };
  }, []);

  const addNewHandler = (typeaheadRef) => {
    if(newPobId && newPobId.length > 0 && !data.whitelist.includes(newPobId)) {
      setData({whitelist: [{pobId: newPobId, disabled: false}, ...data.whitelist ]});
      setNewPobId('');
      if(typeaheadRef && typeaheadRef.getInstance)
        typeaheadRef.getInstance().clear();
    }
  };
  const onChangeHandler = values => {
    const pobId = (values && values.length > 0 && values[0] && values[0].pobId) || '';
    setNewPobId(pobId);
  };
  const handleToggleDisabled = (pobId) => {
    setData({whitelist: [...data.whitelist.map(obj => obj.pobId === pobId ? {...obj, disabled: !obj.disabled} : {...obj}) ] });
  };
  const handleDelete = (pobId) => {
    setData({whitelist: data.whitelist.filter(obj => obj.pobId !== pobId)});
  };
  const handleSave = () => {
    setSaveInProgress(true);
    api.saveWhitelist(env, domain, data.whitelist)
      .then(result => {
        setInitialData({...result});
        setData({...result});
      })
      .catch(e => setError(e))
      .finally(() => setSaveInProgress(false));
  };
  return (
    <React.Fragment>
      <Navbar bg="light" fixed="bottom">
        <Form inline>
          <SaveProgressButton saveInProgress={saveInProgress} disabled={!canBeSaved} clickHandler={handleSave} />
        </Form>
      </Navbar>
      <Whitelist title={`${getNameForWhitelistDomain(props.domain, props.env)} Whitelist`} newPobId={newPobId} error={error} data={data}
        addNewHandler={addNewHandler} toggleDisabledHandler={handleToggleDisabled} onChangeHandler={onChangeHandler} deleteHandler={handleDelete} {...props}/>

    </React.Fragment>
  );
};
WhitelistContainer.propTypes = {
  domain: PropTypes.string.isRequired,
  env: PropTypes.string.isRequired,
};
export default WhitelistContainer;