import React from 'react';
import PropTypes from 'prop-types';
import { Button, Form, Accordion } from 'react-bootstrap';
import InputWithError from './InputWithError';
import { validateLength, FORM_FIELDS } from '../utils/form_utils';

const PortionPropertiesTableRow = ({item, onChangeHandler, deleteHandler, keys, identifierKey, formErrors, setFormErrors, collapseKey, size}) => {
  return <tr>
    {keys.map(key => {
      if(key.type === 'boolean') {
        return <td key={key.name}> <Form.Check
          type='checkbox'
          name={key.name}
          checked={item[key.name] === true}
          onChange={(e) => onChangeHandler(item[identifierKey], e, true)}
        /></td>;
      } else {
        return (<td key={key.name}>
          {key.name !== 'id' && key.name !== 'ean'
            ? <InputWithError
              type={key.type}
              size={size}
              value={item[key.name] !== null && item[key.name] !== undefined && !Number.isNaN(item[key.name]) ? item[key.name] : ''}
              name={key.name}
              onChangeHandler={(e) => {
                if(key.required) {
                  const error = validateLength(e.target.value);
                  if(setFormErrors) {
                    setFormErrors({...formErrors, [`${item[identifierKey]}_${key.name}`]: error.length > 0 ? {message: error} : undefined});
                  }
                }
                onChangeHandler(item[identifierKey], e, false, key.type === 'number');
              }}
              errorMessage={formErrors && formErrors[`${item[identifierKey]}_${key.name}`] ? formErrors[`${item[identifierKey]}_${key.name}`].message : ''}
            />
            : item[key.name]}
        </td>);
      }
    })}
    <td>
      {collapseKey !== undefined && collapseKey !== null && !Number.isNaN(collapseKey) && collapseKey !== false && (
        <Accordion.Toggle size={size} className='mr-1' as={Button} eventKey={item[identifierKey]} variant="outline-primary">Collapse</Accordion.Toggle>
      )}
      {(!Object.prototype.hasOwnProperty.call(item, FORM_FIELDS.DEFAULT) || Object.prototype.hasOwnProperty.call(item, FORM_FIELDS.DEFAULT) && item.default !== true) && (
        <Button size={size} variant="outline-danger" onClick={() => deleteHandler(item[identifierKey])}>Delete</Button>
      )}

    </td>
  </tr>;
};
PortionPropertiesTableRow.propTypes = {
  item: PropTypes.object,
  onChangeHandler: PropTypes.func,
  deleteHandler:PropTypes.func,
  keys: PropTypes.array,
  identifierKey: PropTypes.string,
  formErrors: PropTypes.object,
  setFormErrors: PropTypes.func,
  collapseKey: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.bool]),
  size: PropTypes.string
};
export default PortionPropertiesTableRow;