
import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import Form from 'react-bootstrap/Form';
import { useHistory, useParams, Link } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import Navbar from 'react-bootstrap/Navbar';
import api from '../api/stations';
import {WEEKDAYS, getValidatedStationData} from '../utils/form_utils';
import FoodStationForm from '../components/FoodStationForm';
import { Alert } from 'react-bootstrap';
import SpinnerButton from '../components/SpinnerButton';
import { useDirtyCheck } from '../utils/hooks';
import LoadingSpinner from '../components/LoadingSpinner';
import Lander from '../components/Lander';

const StationDetails = ({allStations}) => {
  const {pobId} = useParams();
  const history = useHistory();
  const [canBeSaved, setCanBeSaved] = useState(false);
  const [savePending, setSavePending] = useState(false);
  const [backendError, setBackendError] = useState(undefined);
  const [formErrors, setFormErrors] = useState({});
  const [stationData, setStationData] = useState();
  const [initialData, setInitialData] = useState();
  const hasChanges = useDirtyCheck(stationData, initialData);
  useEffect(() => {
    let isMounted = true;
    const hardCodedWeekdays = [{weekday: WEEKDAYS.SAT, openingTimes: {start: '', end: '', type: 'TIMES'}},
      {weekday: WEEKDAYS.SUN, openingTimes: {start: '', end: '', type: 'TIMES'}}];
    api.getStationByPobId(pobId).then(data => {
      if(isMounted) {
        const defaultRestaurantOptions = {restaurantOptions: {servingMode: 'DEFAULT'}};
        const openingTimes = { type: 'TIMES', start: '', end: '' };
        const dataWithWeekdays = data.weekdayOpeningTimes
          ? { openingTimes, ...data, weekdayOpeningTimes: [...data.weekdayOpeningTimes, ...hardCodedWeekdays
            .filter(hardcodedWeekday => !data.weekdayOpeningTimes
              .find(existingWeekdayOpeningtime => existingWeekdayOpeningtime.weekday === hardcodedWeekday.weekday) )]
            .sort((a,b) => a.weekday > b.weekday ? 1 : (b.weekday > a.weekday ? -1 : 0) )}
          : {openingTimes, ...data,
            weekdayOpeningTimes: hardCodedWeekdays};

        setStationData({...defaultRestaurantOptions, ...dataWithWeekdays});
        setInitialData({...defaultRestaurantOptions, ...dataWithWeekdays});
      }
    }).catch(e => {
      setBackendError(e.message);
    });
    return () => {
      isMounted = false;
    };
  }, []);
  useEffect(() => {
    const hasErrors = Object.keys(formErrors).find(key => formErrors[key] !== undefined);
    setCanBeSaved(!hasErrors);
  }, [formErrors]);
  const handleUpdate = () => {
    const newStationData = getValidatedStationData(stationData,allStations);
    setSavePending(true);
    api.updateFoodStation(pobId, newStationData)
      .then(() => {
        setSavePending(false);
        history.push('/stations');
      })
      .catch(e => {
        setSavePending(false);
        console.error(e);
      });
  };
  return (
    <React.Fragment>
      <React.Fragment>
        {!backendError && (
          <Navbar bg="light" fixed="bottom">
            <Form inline>
              {!savePending && (
                <React.Fragment>
                  <Button disabled={!canBeSaved || !hasChanges} variant="success" onClick={handleUpdate}>Save Changes</Button>
                  <Button className="ml-1" as={Link} to="/stations" variant="outline-danger">Cancel</Button>
                </React.Fragment>
              )}
              {savePending && (
                <SpinnerButton variant="success" />
              )}

            </Form>
          </Navbar>
        )}

        <Lander title="Edit Station Details"/>
        {stationData && !backendError && (
          <FoodStationForm hideStationsField name={allStations[stationData.pobId]} stationData={stationData} allStations={allStations}
            formErrors={formErrors} setStationData={setStationData} setFormErrors={setFormErrors}/>
        )}

      </React.Fragment>

      {!backendError && !stationData && (
        <LoadingSpinner />
      )}
      {backendError && (
        <Alert variant="danger">
          <Alert.Heading>{backendError}</Alert.Heading>
        </Alert>
      )}
    </React.Fragment>
  );
};

StationDetails.propTypes = {
  allStations: PropTypes.object.isRequired
};

export default StationDetails;