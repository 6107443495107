import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import BootstrapTable from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import isEqual from 'lodash/isEqual';
import AddRow from './AddRow';
import {parseValue, validateLength, FORM_FIELDS} from '../utils/form_utils';
import ConfigItemRow from './ConfigItemRow';
import SaveProgressButton from './SaveProgressButton';
import { Navbar, Form } from 'react-bootstrap';

const SaveChangesButton = ({disabled, onClick}) =>
  (<Button disabled={disabled} variant="success" onClick={onClick}>
    Save Changes
  </Button>);
SaveChangesButton.propTypes = {
  disabled: PropTypes.bool,
  onClick: PropTypes.func
};

const ConfigTable = ({title, setData, saveInProgress, data, saveHandler, additionalRows, nameMap}) => {
  const [newConfig, setNewConfig] = useState({key: '', value: ''});
  const [formErrors, setFormErrors] = useState({});
  const [canBeSaved, setCanBeSaved] = useState(true);
  const [initialData, setInitialData] = useState(data);
  useEffect(() => {
    const errorKeys = Object.keys(formErrors);
    const hasErrors = errorKeys.find(key => formErrors[key] !== undefined);
    const hasChanges = !isEqual(initialData,data);
    setCanBeSaved(hasChanges && !hasErrors);
  }, [data]);
  const handleUpdateNewConfigKey = (e) => {
    const value = e.target.value.trim();
    if(!Object.keys(data).includes(value)) {
      setNewConfig({...newConfig, key: value});
      setFormErrors({...formErrors, [FORM_FIELDS.NEW_ROW]: undefined});
    } else {
      setNewConfig({...newConfig, key: value});
      setFormErrors({...formErrors, [FORM_FIELDS.NEW_ROW]: {message: 'Configuration with the key already exists.'}});
    }
  };
  const handleDeleteConfigItem = (keyName) => {
    const newConfig = {...Object.keys(data).reduce((object, key) => {
      if(key !== keyName) {
        object[key] = data[key];
      }
      return object;
    }, {})};
    setData({...newConfig});
  };
  const handleUpdateNewConfigValue = (e) => {
    setNewConfig({...newConfig, value: e.target.value.trim()});
  };
  const updateConfigValue = (key, value) => {
    setData({...data, [key]: parseValue(value.trim())});
  };
  const handleAddConfig = () => {
    setData({...data, [newConfig.key] : parseValue(newConfig.value.trim())});
    setNewConfig({key: '', value: ''});
  };
  return (
    <React.Fragment>
      <Navbar bg="light" fixed="bottom">
        <Form inline>
          <SaveProgressButton saveInProgress={saveInProgress} disabled={!canBeSaved} clickHandler={() =>
            saveHandler((res) => {
              setCanBeSaved(false);
              setInitialData({...res});
            })}/>
        </Form>
      </Navbar>
      <h3>{title}</h3>
      <BootstrapTable striped bordered hover>
        <thead>
          <tr>
            <th>Configuration Key</th>
            <th>Value</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {Object.keys(data).filter(key => typeof data[key] !== 'object').map((key) =>
            <ConfigItemRow validator={validateLength} setFormErrors={setFormErrors}
              name={key} keyTitle={nameMap && nameMap[key] ? nameMap[key] : key} key={key} formErrors={formErrors} itemKey={key}
              itemValue={data[key].toString()}
              onChangeHandler={(e) => updateConfigValue(key, e.target.value)} handleDeleteConfigItem={handleDeleteConfigItem}
            />
          )}
          {additionalRows && additionalRows.map(additionalRow => {
            return <ConfigItemRow key={additionalRow.itemKey} {...additionalRow} formErrors={formErrors} handleDeleteConfigItem={handleDeleteConfigItem} setFormErrors={setFormErrors} />;
          })}
          <AddRow
            disabled={(!newConfig.key|| !newConfig.value) || formErrors[FORM_FIELDS.NEW_ROW] && formErrors[FORM_FIELDS.NEW_ROW].message && formErrors[FORM_FIELDS.NEW_ROW].message.length > 0}
            columns={[{value: newConfig.key, handler: handleUpdateNewConfigKey, errorMessage: formErrors[FORM_FIELDS.NEW_ROW] ? formErrors[FORM_FIELDS.NEW_ROW].message : ''}, {value: newConfig.value, handler: handleUpdateNewConfigValue}]} addClickHandler={handleAddConfig}/>
        </tbody>
      </BootstrapTable>
    </React.Fragment>

  );};
ConfigTable.propTypes = {
  title: PropTypes.string,
  setData: PropTypes.func,
  saveInProgress: PropTypes.bool,
  data: PropTypes.object,
  saveHandler: PropTypes.func,
  additionalRows: PropTypes.array,
  nameMap: PropTypes.object
};
export default ConfigTable;