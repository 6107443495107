import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { ListGroup, InputGroup, Form, Button, Alert } from 'react-bootstrap';

const SimpleAddListAddRow = ({maxLength, addHandler, validator}) => {
  const [newValue, setNewValue] = useState('');
  const [error, setError] = useState('');
  useEffect(() => {
    if(newValue.length > 0)
      setError(validator(newValue));
    else setError('');
  }, [newValue]);
  const handleInput = (e) => {
    setNewValue(e.target.value.trim());
  };
  const handleAdd = () => {
    setNewValue('');
    addHandler(newValue);
  };
  return (
    <React.Fragment>
      <InputGroup className="mb-1">
        <Form.Control
          value={newValue}
          onChange={handleInput}
          maxLength={maxLength}
        />
        <InputGroup.Append>
          <Button variant="outline-success" onClick={handleAdd} disabled={newValue.length === 0 || validator(newValue).length > 0}>Add</Button>
        </InputGroup.Append>
      </InputGroup>
      {error.length > 0 && (
        <Alert variant="danger">{error}</Alert>
      )}
    </React.Fragment>
  );
};
SimpleAddListAddRow.propTypes = {
  maxLength: PropTypes.string,
  addHandler: PropTypes.func,
  validator: PropTypes.func
};
const SimpleAddList = ({keyName, values, deleteHandler, addHandler, maxLength, formErrors, setFormErrors, onChangeHandler, validator}) => {
  return (
    <ListGroup className="w-50">
      {values && values.map((value, idx) =>
        (
          <React.Fragment key={idx}>
            <InputGroup className="mb-1">
              <Form.Control name={`${keyName}_${idx}`} value={value}
                onChange={(e) => {
                  const fieldName = e.target.name;
                  const error = validator(e.target.value);
                  if(setFormErrors) {
                    setFormErrors({...formErrors, [fieldName]: error.length > 0 ? {message: error} : undefined});
                  }
                  onChangeHandler(e.target.value.trim(), idx);
                }}
                maxLength={maxLength}
              />
              <InputGroup.Append>
                <Button variant="outline-danger" onClick={() => deleteHandler(value, `${keyName}_${idx}`)}>Delete</Button>
              </InputGroup.Append>
            </InputGroup>
            {formErrors[`${keyName}_${idx}`] && (
              <Alert variant="danger">{formErrors[`${keyName}_${idx}`].message}</Alert>
            )}
          </React.Fragment>
        )
      )}
      <SimpleAddListAddRow maxLength={maxLength} validator={validator} addHandler={addHandler}/>
    </ListGroup>
  );
};


SimpleAddList.propTypes = {
  keyName: PropTypes.string,
  values: PropTypes.array,
  deleteHandler: PropTypes.func,
  addHandler: PropTypes.func,
  maxLength: PropTypes.string,
  formErrors: PropTypes.object,
  setFormErrors: PropTypes.func,
  onChangeHandler: PropTypes.func,
  validator: PropTypes.func
};
export default SimpleAddList;