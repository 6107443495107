import React from 'react';
import PropTypes from 'prop-types';
import PortionsTable from './PortionsTable';
import Card from 'react-bootstrap/Card';
import Accordion from 'react-bootstrap/Accordion';

const PortionGroup = ({ portionGroup, setDraggedItem, idx, handleDragOver, handleDeletePortion,  handleDeletePortionGroup}) => {
  const handleDragEnd = () => setDraggedItem(null);
  const handleDragStart = () => setDraggedItem(portionGroup);
  const dragOver = (e) => {
    e.preventDefault();
    handleDragOver(portionGroup, idx);
  };
  return (

    <Card className="mb-1" draggable onDragStart={handleDragStart} onDragEnd={handleDragEnd} onDragOver={dragOver}>
      <Accordion>
        <Accordion.Toggle as={Card.Header} eventKey={portionGroup.name} className="cursor-pointer">
          {portionGroup.name}
        </Accordion.Toggle>
        <Accordion.Collapse eventKey={portionGroup.name}>
          <Card.Body>
            <PortionsTable handleDeletePortionGroup={handleDeletePortionGroup} handleDeletePortion={handleDeletePortion} portionGroup={portionGroup} />
          </Card.Body>
        </Accordion.Collapse>
      </Accordion>
    </Card>
  );
};
PortionGroup.propTypes = {
  portionGroup: PropTypes.object,
  setDraggedItem: PropTypes.func,
  idx: PropTypes.number,
  handleDragOver: PropTypes.func,
  handleDeletePortion: PropTypes.func,
  handleDeletePortionGroup: PropTypes.func
};
export default PortionGroup;