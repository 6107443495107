import React from 'react';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';
import BootstrapTable from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import PortionListItem from './PortionListItem';

const PortionsTable = ({portionGroup, handleDeletePortionGroup, handleDeletePortion}) => {
  const deletePortionGroupHandler = () => {
    handleDeletePortionGroup(portionGroup.id);
  };
  return (
    <React.Fragment>
      <BootstrapTable striped bordered hover>
        <thead>
          <tr>
            <th>EAN</th>
            <th>Receipt Name</th>
            <th>Name</th>
            <th>Type</th>
            <th>Actions</th>
          </tr>
        </thead>

        <tbody>
          {portionGroup && portionGroup.portions && portionGroup.portions.map(portion => <PortionListItem key={portion.ean} ean={portion.ean}
            receiptName={portion.receiptName} type={portion.type} name={portion.name} handleDeletePortion={handleDeletePortion} portionGroupName={portionGroup.name}/>)}
        </tbody>
      </BootstrapTable>
      <Button as={Link} to={{pathname: '/menu/product/new', portionGroup: portionGroup.name}} variant="success">New Product</Button>
      {' '}
      <Button as={Link} to={{pathname: `/menu/portiongroup/edit/${portionGroup.name}`}} variant="primary">Edit Portion Group</Button>
      {' '}
      <Button variant="danger" onClick={deletePortionGroupHandler}>Delete Portion Group</Button>
    </React.Fragment>

  );
};
PortionsTable.propTypes = {
  portionGroup: PropTypes.object,
  handleDeletePortionGroup: PropTypes.func,
  handleDeletePortion: PropTypes.func
};
export default PortionsTable;