import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import Form from 'react-bootstrap/Form';
import Navbar from 'react-bootstrap/Navbar';
import Alert from 'react-bootstrap/Alert';
import api from '../api/menu';
import { useHistory, useParams, Link } from 'react-router-dom';
import ProductForm from './ProductForm';
import SaveProgressButton from '../components/SaveProgressButton';
import { useErrorChecker, useDirtyCheck } from '../utils/hooks';
import { Button } from 'react-bootstrap';
import LoadingSpinner from '../components/LoadingSpinner';
import Lander from '../components/Lander';
import { getValidatedProduct } from '../utils/form_utils';

const MenuProductDetails = () => {
  const {ean, portionGroup} = useParams();
  const history = useHistory();
  const [product, setProduct] = useState();
  const [formErrors, setFormErrors] = useState({});
  const [saveInProgress, setSaveInProgress] = useState(false);
  const [backendError, setBackendError] = useState(undefined);
  const [initialData, setInitialData] = useState();
  const hasErrors = useErrorChecker(formErrors);
  useEffect(() => {
    let isMounted = true;
    api.getMenuProductDetails(ean, portionGroup).then(data => {
      if(isMounted) {
        setProduct(data);
        setInitialData(data);
      }
    }).catch(e => {
      setBackendError(e.message);
      console.error(e);
    });
    return () => {
      isMounted = false;
    };
  }, []);

  const hasChanges = useDirtyCheck(product,initialData);
  const handleSave = () => {
    setSaveInProgress(true);
    api.updateProduct(ean, getValidatedProduct(product), portionGroup).then(() => {
      setSaveInProgress(false);
      history.push('/menu/portiongroups');
    });
  };
  return (
    <React.Fragment>
      <Navbar bg="light" fixed="bottom">
        <Form inline>
          <SaveProgressButton clickHandler={handleSave} disabled={hasErrors || !hasChanges} saveInProgress={saveInProgress} />
          <Button as={Link} to="/menu/portiongroups" variant="outline-danger" className="ml-1">Cancel</Button>
        </Form>
      </Navbar>
      <Lander title="Edit Product Details" />
      {!backendError && product && (
        <ProductForm formErrors={formErrors} product={product} setFormErrors={setFormErrors} setProduct={setProduct}/>
      )}
      {!backendError && !product && (
        <LoadingSpinner />
      )}
      {backendError && (
        <Alert variant="danger">
          <Alert.Heading>{backendError}</Alert.Heading>
        </Alert>
      )}
    </React.Fragment>
  );
};
MenuProductDetails.propTypes = {
  ean: PropTypes.string,
  history: PropTypes.object
};
export default MenuProductDetails;