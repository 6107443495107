import React from 'react';
import PropTypes from 'prop-types';
import { Route, Switch } from 'react-router-dom';
import MenuProductDetails from './containers/MenuProductDetails';
import MenuNewProduct from './containers/MenuNewProduct';
import StationDetails from './containers/StationDetails';
import StationCreate from './containers/StationCreate';
import Stations from './containers/Stations';
import Home from './containers/Home';
import WhitelistContainer from './containers/WhitelistContainer';
import PortionGroupCreate from './containers/PortionGroupCreate';
import PortionGroupEdit from './containers/PortionGroupEdit';

import Menu from './containers/Menu';
import MenuConfiguration from './containers/MenuConfiguration';
import MerchantCodesContainer from './containers/MerchantCodesContainer';
import FoodStationsConfiguration from './containers/FoodStationsConfiguration';
import NotFound from './components/NotFound';
import { MERCHANT_CODE_DOMAINS } from './utils/generic_utils';
import WashTokens from './containers/WashTokens';

const getComponentForWhitelistDomain = (env, domain, allStations) => {
  return <WhitelistContainer allStations={allStations} domain={domain} env={env}/>;
};

const getPortionGroupNames = (menuData) => {
  return menuData && menuData.menu && menuData.menu.portionGroups.map(pg => pg.name) || [];
};
const getPortionGroupIds = (menuData) => {
  return menuData && menuData.menu && menuData.menu.portionGroups.map(pg => pg.id).filter(pg => pg) || [];
};
const Routes = ({allStations, whitelistConfig, menuData}) => (
  <Switch>
    <Route exact path="/" render={() => <Home whitelistConfig={whitelistConfig}/>}/>
    {whitelistConfig && whitelistConfig.map(({env, domain}) => <Route key={`${domain}_${env}`} exact path={`/whitelist/${env}/${domain}`} render={() => getComponentForWhitelistDomain(env, domain, allStations)}/>)}
    <Route exact path="/stations" render={() => <Stations allStations={allStations}/>} />
    <Route exact path="/stations/configuration" render={() => <FoodStationsConfiguration />} />
    <Route exact path="/stations/edit/:pobId" render={() => <StationDetails allStations={allStations} />}/>
    <Route exact path="/stations/new" render={() => <StationCreate allStations={allStations} />}/>
    <Route exact path="/menu/configuration" component={MenuConfiguration} />
    <Route exact path="/menu/portiongroups" component={Menu}/>
    <Route exact path="/menu/product/new" component={MenuNewProduct} />
    <Route path="/menu/portiongroup/:portionGroup/product/:ean" component={MenuProductDetails}/>
    <Route exact path="/menu/portiongroup/new" render={() => <PortionGroupCreate existingPortionGroupNames={getPortionGroupNames(menuData)} existingPortionGroupIds={getPortionGroupIds(menuData)}/>}/>
    <Route exact path="/menu/portiongroup/edit/:name" render={() => <PortionGroupEdit />} />
    {MERCHANT_CODE_DOMAINS.map(domain => <Route key={domain} exact path={`/merchant-codes/${domain}`} render={() => <MerchantCodesContainer domain={domain} allStations={allStations} />} />)}
    <Route exact path="/washtokens" render={() => <WashTokens/>} />
    <Route render={() => <NotFound whitelistConfig={whitelistConfig}/>} />
  </Switch>
);

Routes.propTypes = {
  allStations: PropTypes.object.isRequired,
  whitelistConfig: PropTypes.array.isRequired,
  menuData: PropTypes.object.isRequired
};

export default Routes;
