import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import omit from 'lodash/omit';
import Form from 'react-bootstrap/Form';
import Navbar from 'react-bootstrap/Navbar';
import SaveProgressButton from '../components/SaveProgressButton';
import api from '../api/menu';
import { useHistory, useLocation, Link } from 'react-router-dom';
import ProductForm from './ProductForm';
import { useErrorChecker } from '../utils/hooks';
import { FORM_FIELDS, getValidatedProduct } from '../utils/form_utils';
import { Button } from 'react-bootstrap';
import LoadingSpinner from '../components/LoadingSpinner';
import Lander from '../components/Lander';
import { Typeahead } from 'react-bootstrap-typeahead';

const ATTRIBUTES_REMOVED_ON_COPY = ['ean', 'validOnDates', 'validOnWeekdays', 'takeaway'];
const MenuNewProduct = () => {
  const location = useLocation();
  const history = useHistory();
  // portion group field is required when it is not pre-populated (i.e. when navigating from url)
  const REQUIRED_FIELDS = [FORM_FIELDS.EAN, FORM_FIELDS.RECEIPT_NAME, FORM_FIELDS.NAME,...[!location.portionGroup ? FORM_FIELDS.PORTION_GROUP : []]];
  const [portionGroupNames, setPortionGroupNames] = useState();
  const [portionGroup, setPortionGroup] = useState(location.portionGroup || '');
  const [product, setProduct] = useState({});
  const [portions, setPortions] = useState([]);
  const defaultFormErrors = REQUIRED_FIELDS.reduce((cur,prev) => {
    cur[prev] = '';
    return cur;
  }, {});
  const [formErrors, setFormErrors] = useState(defaultFormErrors);
  const [saveInProgress, setSaveInProgress] = useState(false);
  const hasErrors = useErrorChecker(formErrors);
  useEffect(() => {
    let isMounted = true;
    api.getPortionGroups().then(data => {
      if(isMounted) {
        setPortions(data.reduce((prev,cur) =>  prev.concat(cur.portions), []));
        setPortionGroupNames(data.map(pg => pg.name));
      }
    }).catch(e => console.error('PORTION GROUPS FETCH FAILED', e));
    return () => {
      isMounted = false;
    };
  }, []);
  const handleSave = () => {
    setSaveInProgress(true);
    if(product && portionGroup) {
      api.createPortionForPortionGroup(getValidatedProduct(product), portionGroup).then(() => {
        setSaveInProgress(false);
        history.push('/menu/portiongroups');
      });
    }
  };
  return (
    <React.Fragment>

      <Navbar bg="light" fixed="bottom">
        <Form inline>
          <SaveProgressButton saveInProgress={saveInProgress} disabled={hasErrors} clickHandler={handleSave} title="Create"/>
          <Button as={Link} to="/menu/portiongroups" variant="outline-danger" className="ml-1">Cancel</Button>
        </Form>
      </Navbar>
      <Lander title="Create a new Product" />
      {product && portionGroupNames && portions && (
        <React.Fragment>
          <Form.Group controlId="formGroupPortions">
            <Form.Label>Copy from...</Form.Label>
            <Typeahead
              id="portion-copy-typeahead"
              clearButton
              labelKey={option => `${option.ean} ${option.name}`}
              onChange={val => {
                setProduct(val && val.length > 0 ? omit({...portions.find(portion => portion.ean === val[0].ean)}, ATTRIBUTES_REMOVED_ON_COPY) : {});
              }
              }
              options={portions.map(portion => ({name: portion.name, ean: portion.ean}))}
              placeholder={'Copy from...'}

            />
          </Form.Group>
          <ProductForm setPortionGroup={setPortionGroup} disableEAN={false} product={product} formErrors={formErrors} setFormErrors={setFormErrors} setProduct={setProduct} portionGroup={portionGroup} portionGroupNames={portionGroupNames}/>
        </React.Fragment>
      )}

      {(!portionGroupNames || !portions) && (
        <LoadingSpinner/>
      )}


    </React.Fragment>
  );
};
MenuNewProduct.propTypes = {
  portionGroupNames: PropTypes.array
};
export default MenuNewProduct;