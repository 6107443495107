import {useState, useEffect} from 'react';
import isEqual from 'lodash/isEqual';
export function useErrorChecker(formErrors) {
  const [hasErrors, setHasErrors] = useState(false);
  useEffect(() => {
    const foundErrors = Object.keys(formErrors).find(key => formErrors[key] !== undefined);
    setHasErrors(!!foundErrors);
  }, [formErrors]);
  return hasErrors;
}

export function useDirtyCheck(data, initialData) {
  const [hasChanges, setHasChanges] = useState();

  useEffect(() => {
    if(data && initialData) {
      const notEqual = !isEqual(initialData,data);
      setHasChanges(data && notEqual);
    }
  }, [data]);
  return hasChanges;
}