import uniq from 'lodash/uniq';
import uniqBy from 'lodash/uniqBy';
import { FORM_FIELDS } from './form_utils';

export const MERCHANT_CODE_DOMAINS = ['food', 'carwash', 'refuel', 'charging'];

export const getNameForWhitelistDomain = (domain, envId) => {
  if(!domain || domain.length === 0) return '';
  if(!envId || envId.length === 0) return '';

  const map = {
    alacarte: `À la carte ${envId.toUpperCase()}`,
    buffet: `Buffet ${envId.toUpperCase()}`,
    carwash: `Carwash ${envId.toUpperCase()}`,
    camera: `Camera ${envId.toUpperCase()}`,
    refuel: `Refuel ${envId.toUpperCase()}`,
    mobile_charging: `Mobile Charging ${envId.toUpperCase()}`
  };
  return map[domain] || `${domain} ${envId.toUpperCase()}`;
};

export const addToChildArray = (array, key, parentId, itemToAdd) => array.map(item => item.id === parentId ? {...item, [key]: [...item[key] || [], itemToAdd]} : {...item});
export const removeFromChildArray = (array, key, parentId, idToRemove) => array.map(item => item.id === parentId ? ({...item, [key]: item[key].filter((c => c.id !== idToRemove)) })  : {...item});

export const getUniqueKeys = (array) => {
  const keys = [];
  array.forEach(item => keys.push(...Object.keys(item).filter(key => typeof item[key] !== 'object') ));
  return uniq(keys).sort();

};

export const getUniqueKeysWithTypes = (array) => {
  const keys = [];

  array.forEach(item => {
    Object.keys(item).forEach(itemKey => {
      if(!keys.find(key => key.name === itemKey) && typeof item[itemKey] !== 'object'){
        if(itemKey === 'id' || itemKey === 'ean') keys.unshift({name: itemKey, type: typeof item[itemKey]});
        else
          keys.push({name: itemKey, type: typeof item[itemKey]});
      }
    });
  });

  return uniqBy(keys, 'name').sort();

};


export const getFallbackKeys = (tableId) => {

  switch(tableId) {
  case PortionPropertiesTableId.CUSTOMIZATION_GROUPS: {
    return [{name: 'id', type: 'string', required: true}, {name: 'name', type: 'string', required: true}];
  }
  case PortionPropertiesTableId.CUSTOMIZATION_GROUP_CUSTOMIZATIONS: {
    return [{name: 'id', type: 'string', required: true}, {name: 'receiptName', type: 'string', required: true}, {name: 'name', type: 'string', required: true}, {name: 'type', type: 'string', required: true} ];
  }
  case PortionPropertiesTableId.CUSTOMIZATION_CHOICES: {
    return [{name: 'id', type: 'string', required: true}, {name: 'name', type: 'string', required: true}];
  }
  case PortionPropertiesTableId.CUSTOMIZATION_CHOICE_CHOICES: {
    return [{name: 'id', type: 'string', required: true}, {name: 'name', type: 'string', required: true}, {name: 'receiptName', type: 'string', required: true}, {name: 'default', type: 'boolean'}];
  }
  case PortionPropertiesTableId.CUSTOMIZATIONS: {
    return [{name: 'id', type: 'string', required: true}, {name: 'name', type: 'string', required: true}];
  }
  case PortionPropertiesTableId.CUSTOMIZATION_CHOICES_WITH_COUNT: {
    return [{name: 'id', type: 'string', required: true}, {name: 'name', type: 'string', required: true}, {name: 'maxTotalCount', type: 'number', required: true}, {name: 'minTotalCount', type: 'number', required: true}];
  }
  case PortionPropertiesTableId.CUSTOMIZATION_CHOICES_WITH_COUNT_CHOICES: {
    return [{name: 'id', type: 'string', required: true}, {name: 'name', type: 'string', required: true}, {name: 'receiptName', type: 'string', required: true}];
  }
  case PortionPropertiesTableId.ADDITIONAL_PRODUCTS: {
    return [{name: 'ean', type: 'string', required: true}, {name: 'receiptName', type: 'string', required: true},{name: 'maxTotalCount', type: 'number' }, {name: 'name', type: 'string', required: true}, {name: 'multipleAllowed', type: 'boolean'}];
  }

  default:
    console.error('no keys found for tableId:', tableId);
    return [];
  }
};
export const ERROR_MESSAGES = {
  REQUIRED_FIELD: 'Field is required.',
  IDENTIFIER_EXISTS: 'Identifier already exists.',
  FIELD_CANNOT_BE_EMPTY: 'Field cannot be empty.',
  INVALID_DATE_FORMAT: 'Invalid format (YYYY-MM-DD).',
  INVALID_TIME_FORMAT: 'Invalid format (00:00 - 23:59).'
};
export const getFieldDefaultValue = (key, tableId) => {
  if(!key || !tableId) return '';

  if(key.type === 'boolean') return false;
  else if (tableId === PortionPropertiesTableId.CUSTOMIZATION_GROUP_CUSTOMIZATIONS && key.name === FORM_FIELDS.TYPE) {
    return 'remove';
  }
  return '';
};
export const PortionPropertiesTableId = {
  ADDITIONAL_PRODUCTS: 'additionalProducts',
  CUSTOMIZATION_CHOICES: 'customizationChoices',
  CUSTOMIZATION_CHOICE_CHOICES: 'customizationChoiceChoices',
  CUSTOMIZATION_CHOICES_WITH_COUNT: 'customizationChoicesWithCount',
  CUSTOMIZATION_CHOICES_WITH_COUNT_CHOICES: 'customizationChoicesWithCountChoices',
  CUSTOMIZATION_GROUPS: 'customizationGroups',
  CUSTOMIZATIONS: 'customizations',
  CUSTOMIZATION_GROUP_CUSTOMIZATIONS: 'customizationGroupCustomizations',
};

export const deletePortionGroup = (existingPortionGroups, idOfPortionGroupToDelete) => {
  if(!idOfPortionGroupToDelete) {
    console.warn('No portion group id provided, won\'t delete...');
    return existingPortionGroups;
  } else if (existingPortionGroups.filter(pg => pg.id === idOfPortionGroupToDelete).length > 1) {
    console.warn('Got more than one result, won\'t delete...');
    return existingPortionGroups;
  }
  return existingPortionGroups.filter(pg => pg.id !== idOfPortionGroupToDelete);
};