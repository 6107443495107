import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';
import SpinnerButton from './SpinnerButton';

const SaveProgressButton = ({saveInProgress, disabled, clickHandler, title}) => saveInProgress ? <SpinnerButton variant="success"/> : <Button variant="success" disabled={disabled} onClick={clickHandler}>{title}</Button>;

SaveProgressButton.propTypes = {
  saveInProgress: PropTypes.bool,
  disabled: PropTypes.bool,
  clickHandler: PropTypes.func,
  title: PropTypes.string
};
SaveProgressButton.defaultProps = {
  title: 'Save Changes'
};
export default SaveProgressButton;