import React, {useState} from 'react';
import PropTypes from 'prop-types';
import Form from 'react-bootstrap/Form';
import Navbar from 'react-bootstrap/Navbar';
import {useErrorChecker} from '../utils/hooks';
import api from '../api/menu';
import { useHistory, useLocation, Link } from 'react-router-dom';
import { Alert, Button } from 'react-bootstrap';
import PortionGroupForm from '../components/PortionGroupForm';
import SaveProgressButton from '../components/SaveProgressButton';
import LoadingSpinner from '../components/LoadingSpinner';
import Lander from '../components/Lander';

const PortionGroupCreate = ({existingPortionGroupNames, existingPortionGroupIds}) => {
  const history = useHistory();
  const location = useLocation();
  const [error, setError] = useState();
  const [formErrors, setFormErrors] = useState({id: '', category: '', name: ''});
  const [saveInProgress, setSaveInProgress] = useState(false);
  const [portionGroup, setPortionGroup] = useState({
    id: '',
    category: '',
    name: '',
    extraDrinkNotAllowed: false,
    portions: []
  });
  const hasErrors = useErrorChecker(formErrors);
  const gotExistingPortionGroupNames = location.existingPortionGroupNames && location.existingPortionGroupNames.length > 0 || existingPortionGroupNames && existingPortionGroupNames.length > 0;
  const gotExistingPortionGroupIds = location.existingPortionGroupIds && location.existingPortionGroupIds.length > 0 || existingPortionGroupIds && existingPortionGroupIds.length > 0;
  const gotData = gotExistingPortionGroupIds && gotExistingPortionGroupNames;
  const handleSave = () => {
    setSaveInProgress(true);
    api.createPortionGroup(portionGroup).then(() => {
      setSaveInProgress(false);
      history.push('/menu/portiongroups');
    }).catch(e => {
      console.error('Portion group creation failed', e);
      setSaveInProgress(false);
      setError(e);
    });
  };
  return (
    <React.Fragment>
      <Navbar bg="light" fixed="bottom">
        <Form inline>
          <SaveProgressButton clickHandler={handleSave} disabled={hasErrors} saveInProgress={saveInProgress} title="Create" />
          <Button as={Link} to="/menu/portiongroups" variant="outline-danger" className="ml-1">Cancel</Button>
        </Form>
      </Navbar>
      <Lander title="Create a new Portion Group" />
      {gotData && (
        <PortionGroupForm formErrors={formErrors} portionGroup={portionGroup} setFormErrors={setFormErrors} existingPortionGroupNames={location.existingPortionGroupNames || existingPortionGroupNames} existingPortionGroupIds={location.existingPortionGroupIds || existingPortionGroupIds} setPortionGroup={setPortionGroup}/>
      )}
      {!gotData && !error && (
        <LoadingSpinner />
      )}
      {error && (
        <Alert variant="danger">
          {error.message}
        </Alert>
      )}
    </React.Fragment>
  );
};

PortionGroupCreate.propTypes = {
  existingPortionGroupNames: PropTypes.array,
  existingPortionGroupIds: PropTypes.array
};
PortionGroupCreate.defaultProps = {
  existingPortionGroupNames: [],
  existingPortionGroupIds: []
};
export default PortionGroupCreate;