import React from 'react';
import PropTypes from 'prop-types';
import {FORM_FIELDS, validateTimeInput} from '../utils/form_utils';
import ConfigTable from './ConfigTable';

const FoodStationsConfigTable = ({showSpinner, title, stationsData, setStationsData, saveHandler}) => {
  const CONFIG_KEY_NAME_MAP = {
    'buffetExpirationMinutes' : 'Buffet Expiration (minutes)',
    'defaultEmployeeDiscountPercentage' : 'Employee Discount Percentage'
  };

  const updateDefaultOpeningTime = (key, value) => {
    setStationsData({...stationsData, defaultOpeningTimes: {...stationsData.defaultOpeningTimes, [key]: value.trim()}});
  };


  return <ConfigTable nameMap={CONFIG_KEY_NAME_MAP} title={title} setData={setStationsData} saveHandler={saveHandler} saveInProgress={showSpinner} data={stationsData}
    additionalRows={[
      {
        maxLength: '5',
        onChangeHandler: (e) => updateDefaultOpeningTime('start', e.target.value),
        keyTitle: 'Default Opening Time',
        itemKey: FORM_FIELDS.OPENINGTIME,
        itemValue: stationsData.defaultOpeningTimes.start,
        validator: (value) => validateTimeInput(value.trim(), true),
        allowDelete: false
      },
      {
        maxLength: '5',
        onChangeHandler: (e) => updateDefaultOpeningTime('end', e.target.value),
        keyTitle: 'Default Closing Time',
        itemKey: FORM_FIELDS.CLOSINGTIME,
        itemValue: stationsData.defaultOpeningTimes.end,
        validator: (value) => validateTimeInput(value.trim(), true),
        allowDelete: false
      }
    ]}
  />;
};

FoodStationsConfigTable.propTypes = {
  showSpinner: PropTypes.bool,
  title: PropTypes.string,
  stationsData: PropTypes.object,
  setStationsData: PropTypes.func,
  saveHandler: PropTypes.func,
};

export default FoodStationsConfigTable;