import React from 'react';
import PropTypes from 'prop-types';
import BootstrapTable from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import { Link } from 'react-router-dom';

const StationsTable = ({data, allStations}) => {
  return (<BootstrapTable striped bordered hover>
    <thead>
      <tr>
        <th>pobId</th>
        <th>Name</th>
        <th>tlyId</th>
        <th>taxCode</th>
        <th>Actions</th>
      </tr>
    </thead>
    <tbody>
      {data && data.map(station => (
        <tr key={station.pobId}>
          <td>{station.pobId}</td>
          <td>{allStations ? allStations[station.pobId] : ''}</td>
          <td>{station.tlyId}</td>
          <td>{station.taxCode}</td>
          <td><Link to={`/stations/edit/${station.pobId}`}><Button variant="outline-primary">Edit</Button></Link></td>
        </tr>))}
    </tbody>
  </BootstrapTable>);
};

StationsTable.propTypes = {
  data: PropTypes.array,
  allStations: PropTypes.object,
};
export default StationsTable;