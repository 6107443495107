import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Col, Row, Badge } from 'react-bootstrap';
import _ from 'lodash';

import { MERCHANT_CODE_DOMAINS, getNameForWhitelistDomain } from '../utils/generic_utils';
import LoadingSpinner from '../components/LoadingSpinner';
import Lander from '../components/Lander';
const Home = ({whitelistConfig}) => {
  const isDevEnv = process.env.NODE_ENV === 'dev';
  const hasRevision = isDevEnv && process.env.REV;
  return (
    <div>
      <Lander title="ABC OPS">
        <Badge pill variant="info">
          v {process.env.TAG}
        </Badge>
        {hasRevision && (
          <div>
            <Badge variant="secondary">Revision: {process.env.REV}</Badge>
          </div>
        )}
        <p>Welcome to ABC OPS!</p>
      </Lander>
      <Row>
        <Col xs={12} md={6}>
          <h2>Food Stations</h2>
          <Link to="/stations"><h4>Stations</h4></Link>
          <Link to="/stations/new"><h4>Create a food station</h4></Link>
        </Col>
        <Col xs={12} md={6}>
          <h2>Whitelists</h2>
          {whitelistConfig && whitelistConfig.map(({env, domain}) => <Link key={`${domain}_${env}`} to={`/whitelist/${env}/${domain}`}><h4>{getNameForWhitelistDomain(domain, env)}</h4></Link>)}
          {!whitelistConfig || whitelistConfig.length === 0 && (
            <LoadingSpinner/>
          )}
        </Col>
      </Row>
      <Row>
        <Col xs={12} md={6}>
          <h2>Menu</h2>
          <Link to="/menu/configuration"><h4>Configuration</h4></Link>
          <Link to="/menu/portiongroups"><h4>Portion groups</h4></Link>
          <Link to="/menu/portiongroup/new"><h4>Create a portion group</h4></Link>
          <Link to="/menu/product/new"><h4>Create a product</h4></Link>

        </Col>
        <Col xs={12} md={6}>
          <h2>Merchant Codes</h2>
          {MERCHANT_CODE_DOMAINS.map((domain) => (
            <Link key={`link_merchant_codes_${domain}`} to={`/merchant-codes/${domain}`}><h4>{_.capitalize(domain)}</h4></Link>
          ))}
        </Col>
      </Row>

    </div>
  );
};
Home.propTypes = {
  whitelistConfig: PropTypes.array.isRequired
};
export default Home;