import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import api from '../api/stations';
import Button from 'react-bootstrap/Button';
import Navbar from 'react-bootstrap/Navbar';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import FormControl from 'react-bootstrap/FormControl';
import { Link } from 'react-router-dom';
import StationsTable from '../components/StationsTable';
import Alert from 'react-bootstrap/Alert';
import LoadingSpinner from '../components/LoadingSpinner';
import Lander from '../components/Lander';

const Stations = ({allStations}) => {
  const [stationsData, setStationsData] = useState();
  const [searchTerm, setSearchTerm] = useState('');
  const [error, setError] = useState();
  useEffect(() => {
    let isMounted = true;
    api.getStations().then(data => {
      if(isMounted) {
        setStationsData(data);
      }
    }).catch(e => {
      console.error('STATIONS FETCH FAILED', e);
      setError(e);
    });
    return () => {
      isMounted = false;
    };
  }, []);
  return (
    <React.Fragment>
      {Object.keys(allStations).length > 0 && (
        <Navbar bg="light" fixed="bottom">
          <Form inline>
            <Button as={Link} to="/stations/new" variant="success">Create New</Button>
          </Form>
        </Navbar>
      )}

      <Lander title="Food Stations"/>

      {Object.keys(allStations).length > 0 && stationsData && !error && (
        <div>
          <h2>Stations</h2>
          <InputGroup className="mb-3">
            <FormControl
              type="text"
              value={searchTerm}
              onChange={(e) => {
                setSearchTerm(e.target.value);
              }}
              placeholder="Search by pobId or name..."
              aria-label="Search"
            />
          </InputGroup>
          <StationsTable allStations={allStations} data={stationsData.stations.filter(station => station.pobId.indexOf(searchTerm) > -1 || (allStations && allStations[station.pobId] && allStations[station.pobId].toUpperCase().indexOf(searchTerm.toUpperCase()) > -1) )} />
        </div>


      )}
      {(Object.keys(allStations).length === 0 || !stationsData ) && !error && (
        <LoadingSpinner />
      )}
      {error && (
        <Alert variant="danger">
          <Alert.Heading>{error && error.msg}</Alert.Heading>
          {error && error.message}
        </Alert>
      )}
    </React.Fragment>
  );
};

Stations.propTypes = {
  allStations: PropTypes.object.isRequired
};
Stations.defaultProps = {
  allStations: {}
};
export default Stations;