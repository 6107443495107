
import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Navbar from 'react-bootstrap/Navbar';
import 'react-bootstrap-typeahead/css/Typeahead.css';

import api from '../api/stations';
import {WEEKDAYS, SERVING_MODE, getValidatedStationData} from '../utils/form_utils';
import FoodStationForm from '../components/FoodStationForm';
import SpinnerButton from '../components/SpinnerButton';
import LoadingSpinner from '../components/LoadingSpinner';
import Lander from '../components/Lander';

const StationCreate = ({allStations}) => {
  const history = useHistory();
  const [savePending, setSavePending] = useState(false);
  const [canBeSaved, setCanBeSaved] = useState(false);
  const [foodStationPobIds, setFoodStationPobIds] = useState([]);
  const [formErrors, setFormErrors] = useState({pobId: '', tlyId: '', taxCode: ''});
  const [stationData, setStationData] = useState({
    pobId: '',
    tlyId: '',
    merchantCode: '',
    taxCode: '',
    menuConfig: {
      pizza: false,
      abcBurger: false
    },
    openingTimes: {
      start: '10:00',
      end: '20:00',
      type: 'TIMES'
    },
    weekdayOpeningTimes: [
      {weekday: WEEKDAYS.SAT, openingTimes: {start: '', end: '', type: 'TIMES'}},
      {weekday: WEEKDAYS.SUN, openingTimes: {start: '', end: '', type: 'TIMES'}}
    ],
    restaurantOptions: {
      servingMode: SERVING_MODE.DEFAULT
    }

  });
  useEffect(() => {
    let isMounted = true;
    api.getStations().then(data => {
      if(isMounted){
        if(data && data.stations) {
          setFoodStationPobIds(data.stations.map(station => station.pobId));
        }
        if(data && data.defaultOpeningTimes) {
          setStationData({...stationData, openingTimes: data.defaultOpeningTimes});
        }
      }
    }).catch(e => console.error('STATIONS FETCH FAILED', e));
    return () => {
      isMounted = false;
    };
  }, []);
  useEffect(() => {
    const hasErrors = Object.keys(formErrors).find(key => formErrors[key] !== undefined);
    setCanBeSaved(!hasErrors);
  }, [formErrors]);
  const setPobId = (values) => {
    const pobId = (values[0] && values[0].pobId) || '';
    setStationData({...stationData, pobId});
    const errors = {...formErrors, pobId: undefined};
    setFormErrors({...errors});
  };

  const handleSave = (callback) => {
    const newStationData = getValidatedStationData(stationData, allStations);
    api.saveFoodStation(newStationData)
      .then(() => {
        callback();
        history.push('/stations');
      })
      .catch(e => {
        callback();
        console.error('Food station save failed', e);
      });
  };
  return (
    <React.Fragment>
      <Navbar bg="light" fixed="bottom">
        <Form inline>
          {savePending && (
            <SpinnerButton variant="success" />
          )}
          {!savePending && (
            <Button disabled={!canBeSaved} variant="success" onClick={() => {
              setSavePending(true);
              handleSave(() => setSavePending(false));
            }}>Create</Button>
          )}

        </Form>
      </Navbar>
      <Lander title="Create a New Food Station"/>
      {stationData && (
        <FoodStationForm stationData={stationData} allStations={allStations} foodStationPobIds={foodStationPobIds}
          formErrors={formErrors} setPobId={setPobId} setStationData={setStationData} setFormErrors={setFormErrors} isCreation={true}/>
      )}
      {!stationData && (
        <LoadingSpinner/>
      )}
    </React.Fragment>
  );
};

StationCreate.propTypes = {
  allStations: PropTypes.object.isRequired
};

export default StationCreate;