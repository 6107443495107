import React from 'react';
import PropTypes from 'prop-types';
import BootstrapTable from 'react-bootstrap/Table';
import ListItem from './ListItem';
const WhitelistTable = ({data, deleteHandler, toggleDisabledHandler, allStations}) => {
  return (
    <BootstrapTable striped bordered hover>
      <thead>
        <tr>
          <th>Enabled</th>
          <th>pobId</th>
          <th>Name</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>
        {data && data.length > 0 && data.map((item) => <ListItem disabled={item.disabled} key={item.pobId} name={allStations[item.pobId]} pobId={item.pobId} deleteHandler={deleteHandler} toggleDisabledHandler={toggleDisabledHandler}/>)}
      </tbody>
    </BootstrapTable>
  );
};
WhitelistTable.propTypes = {
  data: PropTypes.array,
  deleteHandler: PropTypes.func,
  toggleDisabledHandler: PropTypes.func,
  allStations: PropTypes.object,
};
export default WhitelistTable;