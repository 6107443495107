import React from 'react';
import PropTypes from 'prop-types';
import {Form, Accordion, Card} from 'react-bootstrap';
import PortionPropertiesTable from './PortionPropertiesTable.js';
import { PortionPropertiesTableId } from '../utils/generic_utils.js';

const CustomizationChoicesWithCount = ({setCustomizationChoicesWithCount, customizationChoicesWithCount, formErrors, setFormErrors}) => {
  const setCustomizationChoicesWithCountChoices = (parentId, choices) => {
    setCustomizationChoicesWithCount(customizationChoicesWithCount.map(cc => {
      return cc.id === parentId ? {...cc, choices} : {...cc};
    }));
  };

  return (
    <Form.Group>
      <Card>
        <Accordion style={{marginBottom: '5px'}}>
          <Card.Header>
            <Card.Title>Customization choices with count</Card.Title>
          </Card.Header>
          <Card.Body>
            <PortionPropertiesTable
              identifierKey={'id'}
              items={customizationChoicesWithCount}
              setItems={setCustomizationChoicesWithCount}
              setFormErrors={setFormErrors}
              formErrors={formErrors}
              hasCollapse={true}
              tableId={PortionPropertiesTableId.CUSTOMIZATION_CHOICES_WITH_COUNT}
            />
          </Card.Body>
          {customizationChoicesWithCount.map(choice => {
            return (
              <React.Fragment key={`${choice.id}_children`}>
                <Accordion.Collapse eventKey={choice.id}>
                  <Card.Body>
                    <Card.Title>{choice.id}</Card.Title>
                    <PortionPropertiesTable
                      identifierKey={'id'}
                      items={choice.choices}
                      setItems={(choices) => setCustomizationChoicesWithCountChoices(choice.id, choices)}
                      setFormErrors={setFormErrors}
                      formErrors={formErrors}
                      size="sm"
                      tableId={PortionPropertiesTableId.CUSTOMIZATION_CHOICES_WITH_COUNT_CHOICES}
                    />
                  </Card.Body>
                </Accordion.Collapse>
              </React.Fragment>
            );
          })}
        </Accordion>
      </Card>
    </Form.Group>
  );
};
CustomizationChoicesWithCount.propTypes = {
  setCustomizationChoicesWithCount: PropTypes.func,
  customizationChoicesWithCount: PropTypes.array,
  formErrors: PropTypes.object,
  setFormErrors: PropTypes.func
};
CustomizationChoicesWithCount.defaultProps = {
  customizationChoicesWithCount: []
};
export default CustomizationChoicesWithCount;