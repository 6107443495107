import React, {useState, useEffect} from 'react';
import ConfigTable from '../components/ConfigTable';
import api from '../api/menu';
import LoadingSpinner from '../components/LoadingSpinner';
import Lander from '../components/Lander';

const MenuConfiguration = () => {
  const [menuConfig, setMenuConfig] = useState();
  const [showSpinner, setShowSpinner] = useState(false);
  useEffect(() => {
    let isMounted = true;
    api.getMenu().then(data => {
      if(isMounted) {
        if(data && data.menu && data.menu.config)
          setMenuConfig(data.menu.config);
      }
    }).catch(e => console.error('MENU FETCH FAILED', e));
    return () => {
      isMounted = false;
    };
  }, []);
  const handleSaveMenuConfig = (callback) => {
    setShowSpinner(true);
    api.saveMenuConfig(menuConfig).then(res => {
      setMenuConfig({...res});
      setShowSpinner(false);
      callback(res);
    }).catch(e => console.error(e));
  };
  return (
    <React.Fragment>
      <Lander title="Menu Configuration" />
      {menuConfig && (
        <div>
          <ConfigTable title="Configuration" setData={setMenuConfig} saveHandler={handleSaveMenuConfig} saveInProgress={showSpinner} data={menuConfig}/>
        </div>
      )}
      {!menuConfig && (
        <LoadingSpinner />
      )}
    </React.Fragment>
  );
};

export default MenuConfiguration;