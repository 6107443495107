
import React from 'react';
import PropTypes from 'prop-types';
import Form from 'react-bootstrap/Form';
import BootstrapTable from 'react-bootstrap/Table';
import {Typeahead} from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import Alert from 'react-bootstrap/Alert';

import {validateTimeInput, FORM_FIELDS, openingTimeMap, closingTimeMap, WEEKDAYS, SERVING_MODE} from '../utils/form_utils';
import InputWithError from './InputWithError';
const PIZZA_ERROR_MESSAGE = 'Pizza, pizza buffet and pizza & buffa are mutually exclusive.';

const FoodStationForm = ({hideStationsField, stationData, allStations, foodStationPobIds, formErrors, setPobId, setStationData, setFormErrors, isCreation}) => {
  const getHumanReadableWeekDay = (weekday) => {
    if(weekday === WEEKDAYS.SAT) return 'Saturday';
    else if (weekday === WEEKDAYS.SUN) return 'Sunday';
    return weekday;
  };
  const inputChangeHandler = (e) => {
    const name = e.target.name;

    switch(name) {
    case FORM_FIELDS.POBID:
    case FORM_FIELDS.TLYID:
    case FORM_FIELDS.MERCHANTCODE:
    case FORM_FIELDS.TAXCODE: {
      setStationData({...stationData, [name]: e.target.value});
      if(e.target.value.trim().length === 0)
        setFormErrors({...formErrors, [name]: {message: `${name  } cannot be empty`}});
      else if (isCreation && name === FORM_FIELDS.POBID && foodStationPobIds.includes(e.target.value.trim()))
        setFormErrors({...formErrors, [name] : {message: 'Food station with duplicate pobId found.'}});
      else {
        const errors = {...formErrors, [name]: undefined};
        setFormErrors({...errors});
      }
      break;
    }
    case FORM_FIELDS.ARNOLDS:
    case FORM_FIELDS.BURGER: {
      setStationData({...stationData, menuConfig: {...stationData.menuConfig, [name]: !stationData.menuConfig[name]}});
      break;
    }
    case FORM_FIELDS.PIZZA: {
      if(!stationData.menuConfig.pizzaBuffet && !stationData.menuConfig.pizzaWithBuffet) {
        setStationData({...stationData, menuConfig: {...stationData.menuConfig, [name]: !stationData.menuConfig[name]}});
        const errors = formErrors[FORM_FIELDS.PIZZA_BUFFET] ? {...formErrors, [name]: undefined, [FORM_FIELDS.PIZZA_BUFFET]: undefined, [FORM_FIELDS.PIZZA_WITH_BUFFET]: undefined} : {...formErrors, [name]: undefined};
        setFormErrors(errors);
      } else {
        setFormErrors({...formErrors, [name]: {message: PIZZA_ERROR_MESSAGE}});
      }
      break;
    }
    case FORM_FIELDS.PIZZA_BUFFET: {
      if(!stationData.menuConfig.pizza && !stationData.menuConfig.pizzaWithBuffet) {
        setStationData({...stationData, menuConfig: {...stationData.menuConfig, [name]: !stationData.menuConfig[name]}});
        const errors = formErrors[FORM_FIELDS.PIZZA] ? {...formErrors, [name]: undefined, [FORM_FIELDS.PIZZA]: undefined, [FORM_FIELDS.PIZZA_WITH_BUFFET]: undefined} : {...formErrors, [name]: undefined};
        setFormErrors(errors);
      } else {
        setFormErrors({...formErrors, [name]: {message: PIZZA_ERROR_MESSAGE}});
      }
      break;
    }
    case FORM_FIELDS.PIZZA_WITH_BUFFET: {
      if(!stationData.menuConfig.pizza && !stationData.menuConfig.pizzaBuffet) {
        setStationData({...stationData, menuConfig: {...stationData.menuConfig, [name]: !stationData.menuConfig[name]}});
        const errors = formErrors[FORM_FIELDS.PIZZA] ? {...formErrors, [name]: undefined, [FORM_FIELDS.PIZZA]: undefined, [FORM_FIELDS.PIZZA_BUFFET]: undefined} : {...formErrors, [name]: undefined};
        setFormErrors(errors);
      } else {
        setFormErrors({...formErrors, [name]: {message: PIZZA_ERROR_MESSAGE}});
      }
      break;
    }

    case FORM_FIELDS.OPENINGTIME:
    case FORM_FIELDS.CLOSINGTIME: {
      const propertyName = name === FORM_FIELDS.OPENINGTIME ? 'start' : 'end';
      setStationData({...stationData, openingTimes: {...stationData.openingTimes, [propertyName]: e.target.value}});
      const error = validateTimeInput(e.target.value, true);
      setFormErrors({...formErrors, [name]: error.length > 0 ? {message: error} : undefined});
      break;
    }
    case FORM_FIELDS.OPENINGTIME_SUN:
    case FORM_FIELDS.CLOSINGTIME_SUN:
    case FORM_FIELDS.OPENINGTIME_SAT:
    case FORM_FIELDS.CLOSINGTIME_SAT: {
      const propertyName = (name === FORM_FIELDS.OPENINGTIME_SUN || name === FORM_FIELDS.OPENINGTIME_SAT) ? 'start' : 'end';
      const weekday = (name === FORM_FIELDS.OPENINGTIME_SAT || name === FORM_FIELDS.CLOSINGTIME_SAT) ? WEEKDAYS.SAT : WEEKDAYS.SUN;
      const newData = {...stationData};
      newData.weekdayOpeningTimes.find(weekdayObj => weekdayObj.weekday === weekday).openingTimes[propertyName] = e.target.value;
      setStationData({...newData, weekdayOpeningTimes: newData.weekdayOpeningTimes.map(wdot => wdot.weekday === weekday ? {...wdot, openingTimes: {...wdot.openingTimes, [propertyName]: e.target.value}} : {...wdot}) });
      const error = validateTimeInput(e.target.value);
      setFormErrors({...formErrors, [name]: error.length > 0 ? {message: error} : undefined});
      break;
    }
    case FORM_FIELDS.SERVING_MODE_TAKEAWAY: {
      const servingModeCurrentlyTakeaway = stationData && stationData.restaurantOptions && stationData.restaurantOptions.servingMode && stationData.restaurantOptions.servingMode === SERVING_MODE.TAKEAWAY;
      setStationData({...stationData, restaurantOptions: {...stationData.restaurantOptions, servingMode: servingModeCurrentlyTakeaway ? SERVING_MODE.DEFAULT : SERVING_MODE.TAKEAWAY}});
      break;
    }
    case FORM_FIELDS.MARKET_INTEGRATION: {
      setStationData({...stationData, isVoikukkaIntegrationEnabled: !stationData.isVoikukkaIntegrationEnabled});
      break;
    }
    }
  };
  return (<Form>
    {!hideStationsField && (
      <Form.Group controlId="formGroupStation">
        <Form.Label>Station</Form.Label>
        <Typeahead
          id="basic-typeahead-example"
          clearButton
          labelKey={(option) => `${option.pobId} ${option.name}`}
          onChange={setPobId}
          options={allStations && Object.keys(allStations).length > 0 && foodStationPobIds.length > 0 ? Object.keys(allStations).filter(pobId => !foodStationPobIds.includes(pobId)).map(pobId => ({pobId: pobId, name: allStations[pobId] || 'Name not found'})) : []}
          placeholder="Choose a station..."
        />
      </Form.Group>
    )}

    <Form.Group controlId="formGroupName">
      <Form.Label>Name</Form.Label>
      <Form.Control name={FORM_FIELDS.NAME} type="text" value={allStations[stationData.pobId] || ''} readOnly />
    </Form.Group>

    {stationData.pobId !== undefined && (
      <Form.Group controlId="formGroupPobId">
        <Form.Label>{FORM_FIELDS.POBID}</Form.Label>
        <InputWithError value={stationData.pobId} name={FORM_FIELDS.POBID} onChangeHandler={inputChangeHandler}
          errorMessage={formErrors[FORM_FIELDS.POBID] ? formErrors[FORM_FIELDS.POBID].message : ''} type="number" />
      </Form.Group>
    )}

    {stationData.tlyId !== undefined && (
      <Form.Group controlId="formGroupTlyId">
        <Form.Label>{FORM_FIELDS.TLYID}</Form.Label>
        <InputWithError value={stationData.tlyId} name={FORM_FIELDS.TLYID} onChangeHandler={inputChangeHandler}
          errorMessage={formErrors[FORM_FIELDS.TLYID] ? formErrors[FORM_FIELDS.TLYID].message : ''} type="number" />
      </Form.Group>
    )}
    {stationData.taxCode !== undefined && (
      <Form.Group controlId="formGroupTaxCode">
        <Form.Label>Tax Code</Form.Label>
        <InputWithError value={stationData.taxCode} name={FORM_FIELDS.TAXCODE} onChangeHandler={inputChangeHandler}
          errorMessage={formErrors[FORM_FIELDS.TAXCODE] ? formErrors[FORM_FIELDS.TAXCODE].message : ''} />
      </Form.Group>
    )}
    {stationData.menuConfig && (
      <Form.Group controlId="formGroupArnolds">
        <Form.Check name={FORM_FIELDS.ARNOLDS} type="checkbox" label="Arnolds" checked={stationData.menuConfig.arnolds || false} onChange={inputChangeHandler}/>
      </Form.Group>
    )}
    {stationData.menuConfig && (
      <Form.Group controlId="formGroupPizza">
        <Form.Check name={FORM_FIELDS.PIZZA} type="checkbox" label="Pizza" checked={stationData.menuConfig.pizza} onChange={inputChangeHandler}/>
        {formErrors[FORM_FIELDS.PIZZA] && <Alert variant="danger">{formErrors[FORM_FIELDS.PIZZA].message}</Alert>}
      </Form.Group>

    )}
    {stationData.menuConfig && (
      <Form.Group controlId="formGroupBurger">
        <Form.Check name={FORM_FIELDS.BURGER} type="checkbox" label="ABC Burger" checked={stationData.menuConfig.abcBurger} onChange={inputChangeHandler}/>
      </Form.Group>
    )}
    {stationData.menuConfig && (
      <Form.Group controlId="formGroupPizzaBuffet">
        <Form.Check name={FORM_FIELDS.PIZZA_BUFFET} type="checkbox" label="Pizza Buffet" checked={!!stationData.menuConfig.pizzaBuffet} onChange={inputChangeHandler}/>
        {formErrors[FORM_FIELDS.PIZZA_BUFFET] && <Alert variant="danger">{formErrors[FORM_FIELDS.PIZZA_BUFFET].message}</Alert>}
      </Form.Group>
    )}
    {stationData.menuConfig && (
      <Form.Group controlId="formGroupPizzaWithBuffet">
        <Form.Check name={FORM_FIELDS.PIZZA_WITH_BUFFET} type="checkbox" label="Pizza & Buffa" checked={!!stationData.menuConfig.pizzaWithBuffet} onChange={inputChangeHandler}/>
        {formErrors[FORM_FIELDS.PIZZA_WITH_BUFFET] && <Alert variant="danger">{formErrors[FORM_FIELDS.PIZZA_WITH_BUFFET].message}</Alert>}
      </Form.Group>
    )}

    <BootstrapTable striped bordered hover>
      <thead>
        <tr>
          <th>Weekday</th>
          <th>Opening Time</th>
          <th>Closing Time</th>
        </tr>

      </thead>
      <tbody>
        <tr>
          <td>
                  Working Days
          </td>
          <td>
            <InputWithError value={stationData.openingTimes.start}
              name={FORM_FIELDS.OPENINGTIME}
              onChangeHandler={inputChangeHandler}
              maxLength="5"
              errorMessage={formErrors[FORM_FIELDS.OPENINGTIME] ? formErrors[FORM_FIELDS.OPENINGTIME].message : ''}
            />
          </td>
          <td>
            <InputWithError value={stationData.openingTimes.end}
              name={FORM_FIELDS.CLOSINGTIME}
              onChangeHandler={inputChangeHandler}
              maxLength="5"
              errorMessage={formErrors[FORM_FIELDS.CLOSINGTIME] ? formErrors[FORM_FIELDS.CLOSINGTIME].message : ''}
            />
          </td>
        </tr>
        {stationData.weekdayOpeningTimes && stationData.weekdayOpeningTimes.map(weekdayObject => (
          <tr key={weekdayObject.weekday}>
            <td>{getHumanReadableWeekDay(weekdayObject.weekday)}</td>
            <td>
              <InputWithError value={weekdayObject.openingTimes.start}
                name={openingTimeMap[weekdayObject.weekday]}
                onChangeHandler={inputChangeHandler}
                maxLength="5"
                errorMessage={formErrors[openingTimeMap[weekdayObject.weekday]] ? formErrors[openingTimeMap[weekdayObject.weekday]].message : ''}
              />
            </td>
            <td>
              <InputWithError value={weekdayObject.openingTimes.end}
                name={closingTimeMap[weekdayObject.weekday]}
                onChangeHandler={inputChangeHandler}
                maxLength="5"
                errorMessage={formErrors[closingTimeMap[weekdayObject.weekday]] ? formErrors[closingTimeMap[weekdayObject.weekday]].message : ''}
              />
            </td>
          </tr>
        ))}
      </tbody>
    </BootstrapTable>
    <h4>Serving Mode</h4>
    <div className="ml-2">
      <Form.Group controlId="formGroupServingModeTakeawayOnly">
        <Form.Check name={FORM_FIELDS.SERVING_MODE_TAKEAWAY} type="checkbox" label="Takeaway only" checked={stationData && stationData.restaurantOptions && stationData.restaurantOptions.servingMode === SERVING_MODE.TAKEAWAY} onChange={inputChangeHandler}/>
      </Form.Group>
    </div>
    <h4>Market Integration</h4>
    <div className="ml-2">
      <Form.Group controlId="formGroupMarketIntegration">
        <Form.Check name={FORM_FIELDS.MARKET_INTEGRATION} type="checkbox" label="Integration enabled" checked={!!(stationData && stationData.isVoikukkaIntegrationEnabled)} onChange={inputChangeHandler}/>
      </Form.Group>
    </div>
  </Form>);
};

FoodStationForm.propTypes = {
  isCreation: PropTypes.bool,
  hideStationsField: PropTypes.bool,
  allStations: PropTypes.object,
  foodStationPobIds: PropTypes.array,
  inputChangeHandler: PropTypes.func,
  setPobId: PropTypes.func,
  formErrors: PropTypes.object,
  setStationData: PropTypes.func,
  setFormErrors: PropTypes.func,
  stationData: PropTypes.shape({
    pobId: PropTypes.string,
    tlyId: PropTypes.string,
    merchantCode: PropTypes.string,
    taxCode: PropTypes.string,
    menuConfig: PropTypes.shape({
      pizza: PropTypes.bool,
      abcBurger: PropTypes.bool,
      arnolds: PropTypes.bool,
      pizzaBuffet: PropTypes.bool,
      pizzaWithBuffet: PropTypes.bool,
    }),
    openingTimes: PropTypes.shape({
      start: PropTypes.string,
      end: PropTypes.string,
      type: PropTypes.string
    }),
    weekdayOpeningTimes: PropTypes.arrayOf(PropTypes.shape({
      weekday: PropTypes.string,
      openingTimes: PropTypes.shape({
        start: PropTypes.string,
        end: PropTypes.string,
        type: PropTypes.string
      })
    })),
    restaurantOptions: PropTypes.shape({
      servingMode: PropTypes.oneOf(['TAKEAWAY_ONLY', 'DINE_IN_ONLY', 'DEFAULT'])
    }),
    isVoikukkaIntegrationEnabled: PropTypes.bool,
  }).isRequired

};

export default FoodStationForm;