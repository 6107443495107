import React from 'react';
import PropTypes from 'prop-types';
import { Alert } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import LoadingSpinner from './LoadingSpinner';
const NotFound = ({whitelistConfig}) => {
  const location = useLocation();
  const whitelistMatch = location.pathname.indexOf('/whitelist/') > -1;
  const gotConfig = whitelistConfig.length > 0;
  return (
    <div className="lander">
      {whitelistMatch && !gotConfig && (
        <LoadingSpinner />
      )}
      {((whitelistMatch && gotConfig) || !whitelistMatch) && (
        <Alert variant="danger">
          <Alert.Heading>Page Not Found</Alert.Heading>
        </Alert>
      )}
    </div>
  );
};
NotFound.propTypes = {
  whitelistConfig: PropTypes.array
};

export default NotFound;