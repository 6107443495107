import React from 'react';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';
import Button from 'react-bootstrap/Button';

const PortionListItem = ({ean, receiptName, name, type, handleDeletePortion, portionGroupName}) =>  {
  const handleDelete = () => handleDeletePortion(portionGroupName, ean);
  return (
    <tr>
      <td>{ean}</td>
      <td>{receiptName}</td>
      <td>{name}</td>
      <td>{type}</td>
      <td><Button as={Link} to={`/menu/portiongroup/${portionGroupName}/product/${ean}`} variant="outline-primary">Edit</Button>{' '}<Button variant="outline-danger" onClick={handleDelete}>Delete</Button></td>
    </tr>
  );
};
PortionListItem.propTypes = {
  ean: PropTypes.string,
  receiptName: PropTypes.string,
  name: PropTypes.string,
  type: PropTypes.string,
  handleDeletePortion: PropTypes.func,
  portionGroupName: PropTypes.string
};

export default PortionListItem;