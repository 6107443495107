import { AuthenticationContext, adalGetToken, adalFetch } from 'react-adal';
const adalConfig = {
  tenant: 'a652adc3-7bb3-4312-8eb0-6ab323f7d6cd',
  clientId: process.env.AZURE_CLIENT_ID,
  endpoints: {
    graphAPI: 'https://graph.windows.net/'
  },
  postLogoutRedirectUri: window.location.origin,
  redirectUri: window.location.origin,
  cacheLocation: 'localStorage'
};
export const authContext = new AuthenticationContext(adalConfig);
export const getToken = () => adalGetToken(authContext, adalConfig.clientId);
export const getGraphAccessToken = () => adalGetToken(authContext, adalConfig.endpoints.graphAPI);
export const getCachedUser = () => authContext.getCachedUser();
export const adalApiFetch = (fetch, url, options) =>
  adalFetch(authContext, adalConfig.endpoints.graphAPI, fetch, url, options);

export const adalAcquireTokenRedirect = () => authContext.acquireTokenRedirect(adalConfig.endpoints.graphAPI);