import React from 'react';
import PropTypes from 'prop-types';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';

const SpinnerButton = ({variant, className}) =>
  (<Button variant={variant} disabled className={className}>
    <Spinner
      as="span"
      animation="border"
      size="sm"
      role="status"
      aria-hidden="true"
    />
  </Button>);

SpinnerButton.propTypes = {
  variant: PropTypes.string,
  className: PropTypes.string
};
SpinnerButton.defaultProps = {
  variant: 'primary'
};
export default SpinnerButton;