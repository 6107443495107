import {handleFetch, handleSave, HttpMethod} from './api.js';

const URL = `${process.env.REST_API_BASE}/ops/whitelist`;
const V2_URL = `${process.env.REST_API_BASE}/ops/v2/whitelist`;

const getAvailableWhitelists = async () => {
  const whitelists = await handleFetch(`${process.env.REST_API_BASE}/ops/configuration/whitelists`, HttpMethod.GET);
  return whitelists;
};
const getWhitelist = async (env, domain) => {
  const whitelist = await handleFetch(`${URL}/${env}/${domain}`, HttpMethod.GET);
  return whitelist;
};
const saveWhitelist = async (env, domain, whitelist) => {
  const data = await handleSave(`${V2_URL}/${env}/${domain}`, {whitelist});
  return data;
};
export default {getAvailableWhitelists, getWhitelist, saveWhitelist};