import omit from 'lodash/omit';
import { ERROR_MESSAGES } from './generic_utils';
export const SERVING_MODE = {
  DEFAULT: 'DEFAULT',
  TAKEAWAY: 'TAKEAWAY_ONLY',
  DINE_IN: 'DINE_IN_ONLY',
};
export const FORM_FIELDS = {
  EAN: 'ean',
  ID: 'id',
  POBID: 'pobId',
  TLYID: 'tlyId',
  MERCHANTCODE: 'merchantCode',
  TAXCODE: 'taxCode',
  ARNOLDS: 'arnolds',
  PIZZA: 'pizza',
  PIZZA_BUFFET: 'pizzaBuffet',
  PIZZA_WITH_BUFFET: 'pizzaWithBuffet',
  BURGER: 'abcBurger',
  SERVING_MODE_TAKEAWAY: 'servingModeTakeaway',
  SERVING_MODE_DINE_IN: 'servingModeDineIn',
  SERVING_MODE_DEFAULT: 'servingModeDefault',
  OPENINGTIME: 'openingTime',
  OPENINGTIME_SAT: 'openingTimeSat',
  OPENINGTIME_SUN: 'openingTimeSun',
  CLOSINGTIME: 'closingTime',
  CLOSINGTIME_SAT: 'closingTimeSat',
  CLOSINGTIME_SUN: 'closingTimeSun',
  NAME: 'name',
  RECEIPT_NAME: 'receiptName',
  TYPE: 'type',
  EXCLUDED_FROM_EMPLOYEE_DISCOUNT: 'excludedFromEmployeeDiscount',
  NEW_ROW: 'newRow',
  IS_DEFAULT: 'isDefault',
  CATEGORY: 'category',
  MENU_CONTENT_DESCRIPTION: 'menuContentDescription',
  VALID_FROM: 'validFrom',
  VALID_TO: 'validTo',
  PORTION_GROUP: 'portionGroup',
  EXTRA_DRINK_NOT_ALLOWED: 'extraDrinkNotAllowed',
  DEFAULT: 'default',
  CART_PRIORITY: 'cartPriority',
  SHOW_NUTRIENTS: 'showNutrients',
  MARKET_INTEGRATION: 'marketIntegration',
  TAKEAWAY_EAN: 'takeawayEan',
  TAKEAWAY_RECEIPT_NAME: 'takeawayReceiptName',
};
export const WEEKDAYS = {
  SAT: 'SAT',
  SUN: 'SUN'
};
export const openingTimeMap = {
  [WEEKDAYS.SAT]: FORM_FIELDS.OPENINGTIME_SAT,
  [WEEKDAYS.SUN]: FORM_FIELDS.OPENINGTIME_SUN
};

export const closingTimeMap = {
  [WEEKDAYS.SAT]: FORM_FIELDS.CLOSINGTIME_SAT,
  [WEEKDAYS.SUN]: FORM_FIELDS.CLOSINGTIME_SUN
};
export const parseValue = (value) => {
  if(value === '') return undefined;

  if(typeof value === 'boolean') {
    return value;
  } else if(value === 'true' || value === 'false') {
    return value === 'true';
  } else if (!isNaN(value)) {
    return parseFloat(value);
  }
  return value;
};
export const validateTimeInput = (value, validateLength = false) => {
  const tester = /^([0-1][0-9]|[2][0-3]):([0-5][0-9])$/;
  if(validateLength && value && value.trim().length === 0 || validateLength && !value) {
    return ERROR_MESSAGES.FIELD_CANNOT_BE_EMPTY;
  } else if (value && value.length > 0 && !tester.test(value)) {
    return ERROR_MESSAGES.INVALID_TIME_FORMAT;
  } else {
    return '';
  }
};

export const validateLength = (value) => {
  if(!value || value.trim().length === 0)
    return ERROR_MESSAGES.FIELD_CANNOT_BE_EMPTY;
  return '';
};

export const validateDateInput = (value) => {
  const tester = /^(\d{4})-([0][1-9]|[1][0-2])-([0][1-9]|[1-2][0-9]|[3][0-1])$/;
  if (value && value.length > 0 && !tester.test(value)) {
    return ERROR_MESSAGES.INVALID_DATE_FORMAT;
  }
  return '';
};
export const validateDateAndLength = (value) => {
  const dateValidationErrors = validateDateInput(value);
  const lengthErrors = validateLength(value);
  if(dateValidationErrors) return dateValidationErrors;
  return lengthErrors;
};

export const getValidatedStationData = (stationData, allStations) => {
  const cleanedWeekdayOpeningTimes = stationData.weekdayOpeningTimes.filter(
    weekdayObj => (weekdayObj.openingTimes.start && weekdayObj.openingTimes.start.length > 0 && weekdayObj.openingTimes.end && weekdayObj.openingTimes.end.length > 0)
  );
  return {
    name: allStations[stationData.pobId] || '',
    pobId: stationData.pobId,
    tlyId: stationData.tlyId,
    merchantCode: 'NOT_IN_USE',
    taxCode: stationData.taxCode,
    menuConfig: {...stationData.menuConfig},
    openingTimes: {...stationData.openingTimes},
    restaurantOptions: {...stationData.restaurantOptions},
    ...(cleanedWeekdayOpeningTimes.length > 0 ? {weekdayOpeningTimes: cleanedWeekdayOpeningTimes } : {}),
    isVoikukkaIntegrationEnabled: stationData.isVoikukkaIntegrationEnabled,
  };
};

export const getValidatedProduct = (product) => {
  const hasValidTakeawayObject = (product.takeaway && product.takeaway.ean && product.takeaway.receiptName);
  if(!product.takeaway || hasValidTakeawayObject) {
    return product;
  } else {
    // invalid takeaway object
    return {...omit(product, ['takeaway'])};
  }
};