import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Lander from '../components/Lander';
import api from '../api/merchantCodes';
import { Button, Form, Navbar, InputGroup, FormControl } from 'react-bootstrap';
import SaveProgressButton from '../components/SaveProgressButton';
import { Typeahead } from 'react-bootstrap-typeahead';
import { useDirtyCheck } from '../utils/hooks';
import MerchantCodeTable from '../components/MerchantCodeTable';
import LoadingSpinner from '../components/LoadingSpinner';

const MerchantCodesContainer = ({domain, allStations}) => {
  const [data, setData] = useState();
  const [initialData, setInitialData] = useState();
  const [typeahead, setTypeAhead] = useState();
  const [fetchingData, setFetchingData] = useState();
  const [searchTerm, setSearchTerm] = useState('');
  const [newItem, setNewItem] = useState({merchantCode: '', name: '', pobId: ''});
  const [saveInProgress, setSaveInProgress] = useState(false);
  const canBeSaved = useDirtyCheck(data, initialData);
  useEffect(() => {
    let isMounted = true;
    setFetchingData(true);
    api.getDomainMerchantCodes(domain).then(data => {
      if(isMounted) {
        setFetchingData(false);
        setData(data);
        setInitialData(data);
      }
    }).catch(e => {
      console.error(`Failed to fetch merchant codes for domain: ${domain}`, e);
      setFetchingData(false);
    });
    return () => {
      isMounted = false;
    };
  }, []);
  const deleteItem = (pobId) => setData(data.filter(item => item.pobId !== pobId));
  const newItemIsValid = () => newItem && newItem.merchantCode && newItem.merchantCode.length > 0 && newItem.name && newItem.name.length > 0 && newItem.pobId && newItem.pobId.length > 0;
  const addItem = () => {
    if(newItemIsValid()) {
      setData([newItem, ...data]);
      setNewItem({pobId: '', merchantCode: '', name: ''});
      clearTypeAhead();
    }
  };
  const clearTypeAhead = () => {
    if(typeahead && typeahead.getInstance()){
      typeahead.getInstance().clear();
    } else console.error('Could not get typeahead ref!');
  };
  const typeaheadChangeHandler = (values) => {
    if(values && values.length > 0) {
      const [{pobId, name}] = values;
      setNewItem({...newItem, pobId, name});
    } else {
      setNewItem({...newItem, pobId: '', name: ''});
    }
  };
  const inputHandler = (e) => setNewItem({...newItem, [e.target.name]: e.target.value.trim()});
  const saveHandler = () => {
    setSaveInProgress(true);
    api.saveDomainMerchantCodes(domain, data).then(result => {
      setInitialData(result);
      setData(result);
      setSaveInProgress(false);
    }).catch(e => {
      console.error('save failed', e);
      setSaveInProgress(false);
    });
  };
  return (
    <React.Fragment>
      <Lander title={`${domain[0].toUpperCase()}${domain.substr(1,domain.length-1)} Merchant Codes`}/>
      {!fetchingData && (
        <React.Fragment>
          <Navbar bg="light" fixed="bottom">
            <Form inline>
              <SaveProgressButton saveInProgress={saveInProgress} disabled={!canBeSaved} clickHandler={saveHandler} />
            </Form>
          </Navbar>
          <InputGroup className="mb-3">
            <FormControl
              type="text"
              onChange={(e) => {
                setSearchTerm(e.target.value);
              }}
              placeholder="Search by pobId, name or merchant code..."
              aria-label="Search"
            />
          </InputGroup>
          <InputGroup className="mb-1">
            <Typeahead
              name="typeahead"
              id="merchant-codes-typeahead"
              ref={typeahead => setTypeAhead(typeahead)}
              clearButton
              labelKey={(option) => `${option.pobId} ${option.name}`}
              onChange={typeaheadChangeHandler}
              options={allStations && Object.keys(allStations).length > 0 && data ? Object.keys(allStations).filter(pobId => !data.map(station => station.pobId).includes(pobId)).map(pobId => ({pobId: pobId, name: allStations[pobId] || 'Name not found'})) : []}
              placeholder={allStations && Object.keys(allStations).length > 0 ? 'Choose a station...' : 'Loading stations...'}
            />
            <Form.Control placeholder="Merchant Code" id="merchant-code-input" type="text" name="merchantCode" value={newItem.merchantCode} onChange={inputHandler}/>
            <InputGroup.Append>
              <Button variant="success" className="mb-1" disabled={newItem.merchantCode.length === 0 || newItem.name.length === 0 || newItem.pobId.length === 0} onClick={addItem}>Add</Button>
            </InputGroup.Append>
          </InputGroup>
          <MerchantCodeTable data={data} searchTerm={searchTerm} deleteHandler={deleteItem}/>
        </React.Fragment>
      )}
      {fetchingData && (
        <LoadingSpinner/>
      )}
    </React.Fragment>
  );
};

MerchantCodesContainer.propTypes = {
  domain: PropTypes.string.isRequired,
  allStations: PropTypes.object.isRequired
};
export default MerchantCodesContainer;