import {handleFetch, handleSave, HttpMethod} from './api.js';
const URL = `${process.env.REST_API_BASE}/ops/configuration/merchant-codes`;
const getDomainMerchantCodes = async (domain) => {
  return await handleFetch(`${URL}/${domain}`, HttpMethod.GET);
};
const saveDomainMerchantCodes = async (domain, data) => {
  return await handleSave(`${URL}/${domain}`, {
    stations: data
  });
};

export default {
  getDomainMerchantCodes,
  saveDomainMerchantCodes
};