import React from 'react';
import PropTypes from 'prop-types';
import {Form, Accordion, Card} from 'react-bootstrap';
import PortionPropertiesTable from './PortionPropertiesTable.js';
import { PortionPropertiesTableId } from '../utils/generic_utils.js';

const CustomizationGroups = ({setCustomizationsGroups, customizationsGroups, formErrors, setFormErrors}) => {
  const setCustomizations = (parentId, customizations) => {
    setCustomizationsGroups(customizationsGroups.map(cg => {
      return cg.id === parentId ? {...cg, customizations} : {...cg};
    }));
  };
  return (
    <Form.Group>
      <Card>
        <Accordion defaultActiveKey="0">
          <Card.Header>
            <Card.Title>Customization groups</Card.Title>
          </Card.Header>
          <Card.Body>
            <PortionPropertiesTable
              identifierKey={'id'}
              items={customizationsGroups}
              setItems={setCustomizationsGroups}
              setFormErrors={setFormErrors}
              formErrors={formErrors}
              hasCollapse={true}
              tableId={PortionPropertiesTableId.CUSTOMIZATION_GROUPS}
            />
          </Card.Body>
          {customizationsGroups.map(customizationGroup => {
            return (
              <React.Fragment key={`${customizationGroup.id}_children`}>
                <Accordion.Collapse eventKey={customizationGroup.id}>
                  <Card.Body>
                    <Card.Title>{customizationGroup.id}</Card.Title>
                    <PortionPropertiesTable
                      identifierKey={'id'}
                      items={customizationGroup.customizations}
                      setItems={(customizations) => setCustomizations(customizationGroup.id, customizations)}
                      setFormErrors={setFormErrors}
                      formErrors={formErrors}
                      size="sm"
                      tableId={PortionPropertiesTableId.CUSTOMIZATION_GROUP_CUSTOMIZATIONS}
                    />
                  </Card.Body>
                </Accordion.Collapse>
              </React.Fragment>
            );
          })}
        </Accordion>
      </Card>
    </Form.Group>
  );
};

CustomizationGroups.propTypes = {
  setCustomizationsGroups: PropTypes.func,
  customizationsGroups: PropTypes.array,
  formErrors: PropTypes.object,
  setFormErrors: PropTypes.func
};
CustomizationGroups.defaultProps = {
  customizationsGroups: []
};
export default CustomizationGroups;