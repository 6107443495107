import React, {useEffect, useState} from 'react';
import api from '../api/menu';
import PortionGroup from '../components/PortionGroup';
import { Button, Navbar, Form } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import SaveProgressButton from '../components/SaveProgressButton';
import LoadingSpinner from '../components/LoadingSpinner';
import Lander from '../components/Lander';
import { useDirtyCheck } from '../utils/hooks';
import { deletePortionGroup } from '../utils/generic_utils';


const Menu = () => {
  const [fetchingMenuData, setFetchingMenuData] = useState(true);
  const [saveInProgress, setSaveInProgress] = useState(false);
  const [portionGroups, setPortionGroups] = useState();
  const [draggedItem, setDraggedItem] = useState();
  const [initialData, setInitialData] = useState();
  const canBeSaved = useDirtyCheck(portionGroups, initialData);
  useEffect(() => {
    let isMounted = true;
    api.getMenu().then(data => {
      if(isMounted) {
        setFetchingMenuData(false);
        if(data && data.menu && data.menu.portionGroups) {
          setPortionGroups(data.menu.portionGroups);
          setInitialData(data.menu.portionGroups);
        }
      }
    }).catch(e => console.error('MENU FETCH FAILED', e));
    return () => {
      isMounted = false;
    };
  }, []);

  const handleDragOver = (portionGroup, idx) => {
    if(draggedItem.name === portionGroup.name) {
      return;
    }

    let items = portionGroups.filter(item => item.name !== draggedItem.name);
    items.splice(idx, 0, draggedItem);

    setPortionGroups([...items]);
  };
  const handleDeletePortionGroup = (portionGroupId) => {
    setPortionGroups(deletePortionGroup(portionGroups, portionGroupId));
  };
  const handleDeletePortion = (portionGroupName, ean) => {
    setPortionGroups(portionGroups.map(pg => pg.name === portionGroupName ? ({...pg, portions: pg.portions.filter(portion => portion.ean !== ean)}) : ({...pg})));
  };
  const handleSavePortionGroups = () => {
    setSaveInProgress(true);
    api.savePortionGroups(portionGroups).then(res => {
      setSaveInProgress(false);
      setInitialData([...res]);
      setPortionGroups([...res]);
    });
  };
  return (
    <React.Fragment>
      {portionGroups && (
        <Navbar bg="light" fixed="bottom">
          <Form inline>
            <SaveProgressButton saveInProgress={saveInProgress} disabled={!canBeSaved} clickHandler={handleSavePortionGroups} />
            <Button className="ml-1" as={Link} to={{pathname: '/menu/portiongroup/new', existingPortionGroupNames: portionGroups.map(pg => pg.name), existingPortionGroupIds: portionGroups.map(pg => pg.id).filter(pg => pg)}} variant="outline-success">New Portion Group</Button>
          </Form>
        </Navbar>
      )}

      <Lander title="Portion Groups Configuration" />
      {portionGroups && (
        <React.Fragment>
          <div className="">
            <h3>Portion Groups</h3>
            {portionGroups && portionGroups.map((pg,idx) => {
              return (
                <PortionGroup handleDeletePortionGroup={handleDeletePortionGroup} handleDeletePortion={handleDeletePortion} handleDragOver={handleDragOver} key={pg.name} portionGroup={pg} draggedItem={draggedItem} setDraggedItem={setDraggedItem} idx={idx}/>
              );
            })}
          </div>
        </React.Fragment>
      )}
      {fetchingMenuData && (
        <LoadingSpinner/>
      )}
    </React.Fragment>
  );
};

export default Menu;