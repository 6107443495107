import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';

const MerchantCodeTableRow = ({item, deleteHandler}) => {
  return (
    <tr>
      <td>{item.pobId}</td>
      <td>{item.name}</td>
      <td>{item.merchantCode}</td>
      <td><Button variant="outline-danger" onClick={() => deleteHandler(item.pobId)}>Delete</Button></td>
    </tr>
  );
};
MerchantCodeTableRow.propTypes = {
  item: PropTypes.shape({
    pobId: PropTypes.string.isRequired,
    merchantCode: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired
  }).isRequired,
  deleteHandler: PropTypes.func
};

export default MerchantCodeTableRow;