import React, {useEffect, useState} from 'react';
import { Link } from 'react-router-dom';
import { Navbar, Nav, NavDropdown, Spinner, Container, Alert } from 'react-bootstrap';
import _ from 'lodash';

import Routes from './Routes';
import logo from 'url:../abc-logo.png';
import stationsApi from './api/stations';
import whitelistApi from './api/whitelists';
import menuApi from './api/menu';
import {MERCHANT_CODE_DOMAINS, getNameForWhitelistDomain} from './utils/generic_utils';
import 'bootstrap/dist/css/bootstrap.min.css';
import './styles.css';
const App = () => {
  const [allStations, setAllStations] = useState({});
  const [whitelistConfig, setWhitelistConfig] = useState([]);
  const [menuData, setMenuData] = useState({});
  const [error, setError] = useState();
  useEffect(() => {
    let isMounted = true;
    Promise.all([stationsApi.getAllStations(), whitelistApi.getAvailableWhitelists(), menuApi.getMenu()]).then((result) => {
      const [allStationsResult, whitelistsConfig, menuDataResult] = result;
      const mappedData = allStationsResult.reduce((prev, cur) => {
        prev[cur.id] = cur.name;
        return prev;
      }, {});
      if(isMounted) {
        setAllStations(mappedData);
        setWhitelistConfig(whitelistsConfig);
        setMenuData(menuDataResult);
      }
    }).catch(e => {
      setError(e);
    });
    return () => {
      isMounted = false;
    };
  }, []);
  return error ? <Alert variant="danger">
    <Alert.Heading>{error && error.msg}</Alert.Heading>
    {error && error.message}
  </Alert> : (
    <div className="app container">
      <Navbar bg="light" expand="md" fixed="top" className="navbar-nav">
        <Container>
          <Navbar.Brand as={Link} id="abc-logo" to="/"><img
            src={logo}
            width="40"
            height="60"
            className="d-inline-block align-top"
            alt="ABC logo"
          /></Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">

            <Nav className="mr-auto navbar-nav">
              <Nav.Link href="/" className="home-nav-item">Home</Nav.Link>
              <NavDropdown title="Menu" id="menu-dropdown">
                <NavDropdown.Item as={Link} to={'/menu/configuration'}>Configuration</NavDropdown.Item>
                <NavDropdown.Item as={Link} to={'/menu/portiongroups'} className="border-bottom-1">Portion Groups</NavDropdown.Item>
                <NavDropdown.Item as={Link} to={'/menu/portiongroup/new'}>New Portion Group</NavDropdown.Item>
                <NavDropdown.Item as={Link} to={'/menu/product/new'}>New Product</NavDropdown.Item>
              </NavDropdown>
              <NavDropdown title="Food Stations" id="food-stations-dropdown">
                <NavDropdown.Item as={Link} to={'/stations/configuration'}>Configuration</NavDropdown.Item>
                <NavDropdown.Item as={Link} to={'/stations'} className="border-bottom-1">Stations</NavDropdown.Item>
                <NavDropdown.Item as={Link} to={'/stations/new'}>New Food Station</NavDropdown.Item>
              </NavDropdown>
              {whitelistConfig && whitelistConfig.length > 0 && (
                <NavDropdown title="Whitelists" id="whitelists-dropdown">
                  {whitelistConfig.map(({env,domain}) => <NavDropdown.Item key={`${domain}_${env}`} as={Link} to={`/whitelist/${env}/${domain}`}>{getNameForWhitelistDomain(domain, env)}</NavDropdown.Item>)}
                </NavDropdown>
              )}
              {whitelistConfig && whitelistConfig.length === 0 && (
                <Nav.Link>
                Whitelists
                  {' '}
                  <Spinner animation="border" size="sm"/>
                </Nav.Link>
              )}

              <NavDropdown title="Merchant Codes" id="ec-dropdown">
                {MERCHANT_CODE_DOMAINS.map((domain) => (
                  <NavDropdown.Item key={`nav_merchant_codes_${domain}`} as={Link} to={`/merchant-codes/${domain}`}>{_.capitalize(domain)}</NavDropdown.Item>
                ))}
              </NavDropdown>
              <Nav.Link href="/washtokens">Wash Tokens</Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <Routes whitelistConfig={whitelistConfig} allStations={allStations} menuData={menuData}/>
    </div>
  );
};

export default App;
